import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import {
  getMailImportInfo,
  MailImportMethod,
  MailImportStatus,
  MailImportStatusKind,
  postMailImport,
  PostMailImportResult,
} from "./api/mail-import";

type ImapFormProps = {
  // This is the URL that the user should be redirect to after oauth login. In case of failure,
  // "/oauth-error" is appended to the URL.
  oauthRedirectURL: string;
  year: number;
};

export function ImapForm({ oauthRedirectURL, year }: ImapFormProps) {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [hasFailedUnknown, setHasFailedUnknown] = useState(false);
  const [hasFailedCredentials, setHasFailedCredentials] = useState(false);
  const [imapPassword, setImapPassword] = useState("");

  const [mailImportMethod, setMailImportMethod] = useState(
    null as MailImportMethod | null,
  );
  const [mailImportStatus, setMailImportStatus] = useState(
    null as MailImportStatus | null,
  );

  useEffect(() => {
    (async () => {
      try {
        const info = await getMailImportInfo(year);
        setMailImportMethod(info.availableMethod);
        setMailImportStatus(info.status);
      } catch (err) {
        console.error(`Error while fetching mail import status: ${err}`);
        setHasFailedUnknown(true);
      }
    })();
  }, [year]);

  useEffect(() => {
    if (
      mailImportStatus != null &&
      mailImportStatus.kind !== MailImportStatusKind.InProgress
    ) {
      return;
    }

    setTimeout(async () => {
      try {
        const info = await getMailImportInfo(year);
        setMailImportStatus(info.status);
      } catch (err) {
        console.error(`Error while updating mail import status: ${err}`);
        setHasFailedUnknown(true);
      }
    }, 5000);
  }, [mailImportStatus, year]);

  function handlePasswordChange(ev: ChangeEvent<HTMLInputElement>) {
    ev.preventDefault();
    setImapPassword(ev.target.value);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSubmitInProgress(true);
    try {
      setHasFailedUnknown(false);
      setHasFailedCredentials(false);
      const result = await postMailImport(year, { imapSecret: imapPassword });

      switch (result) {
        case PostMailImportResult.Ok: {
          setMailImportStatus({ kind: MailImportStatusKind.InProgress });
          break;
        }
        case PostMailImportResult.ErrorCredentials: {
          setHasFailedCredentials(true);
          break;
        }
        default: {
          const exhaustive: never = result;
          throw new Error(`Unhandled: ${exhaustive}`);
        }
      }
    } catch (err) {
      setHasFailedUnknown(true);
      console.error(`Error starting mail import: ${err}`);
    } finally {
      setSubmitInProgress(false);
    }
  }

  const emailProviderName: string | null = (() => {
    if (mailImportMethod == null) {
      return null;
    }

    const kind = mailImportMethod.type;
    switch (kind) {
      case "available":
        return null;
      case "availableOAuth":
        return mailImportMethod.emailProviderName;
      case "availableGmail":
        return "Gmail";
      case "unsupportedByUs":
        return mailImportMethod.emailProviderName === undefined
          ? null
          : mailImportMethod.emailProviderName;
      case "unsupportedByThem":
      case "insecure":
        return mailImportMethod.emailProviderName;
      default: {
        const exhaustive: never = kind;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  if (mailImportMethod == null || mailImportStatus == null) {
    return <div className="spinner-border" role="status"></div>;
  }

  const statusView: JSX.Element | null = (() => {
    const kind = mailImportStatus.kind;
    switch (kind) {
      case MailImportStatusKind.NotStarted: {
        return null;
      }
      case MailImportStatusKind.InProgress: {
        return (
          <div className="alert alert-warning" role="alert">
            Emailimport läuft.
          </div>
        );
      }
      case MailImportStatusKind.Completed: {
        const lastStartDate = new Date(mailImportStatus.lastStartDate);

        // TODO: This appears to be render date and time in the UTC timezone, but we want the user's
        // local timezone.
        const dateString = lastStartDate.toLocaleDateString("de-DE");
        const timeString = new Date(
          mailImportStatus.lastStartDate,
        ).toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit" });

        const showTimestamp = lastStartDate.getFullYear() <= year;
        return (
          <div className="alert alert-success" role="alert">
            {showTimestamp
              ? `Emails importiert am ${dateString} um ${timeString}.`
              : "Emails importiert."}
          </div>
        );
      }
      case MailImportStatusKind.Failed: {
        return (
          <div className="alert alert-danger" role="alert">
            Beim Emailabruf ist ein Fehler eingetreten. Versuchen Sie es noch
            einmal oder benutzen Sie eine andere Methode zum Importieren Ihrer
            Dokumente.
          </div>
        );
      }
      default: {
        const exhaustive: never = kind;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  const shouldShowImportForm = (() => {
    const kind = mailImportStatus.kind;
    switch (kind) {
      case MailImportStatusKind.NotStarted: {
        return true;
      }
      case MailImportStatusKind.InProgress: {
        return false;
      }
      case MailImportStatusKind.Completed: {
        const lastImportYear = new Date(
          mailImportStatus.lastStartDate,
        ).getFullYear();
        return lastImportYear <= year;
      }
      case MailImportStatusKind.Failed: {
        return true;
      }
      default: {
        const exhaustive: never = kind;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  const importView: JSX.Element | null = (() => {
    if (!shouldShowImportForm) {
      return null;
    }

    switch (mailImportMethod.type) {
      case "available": {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">
                  Email-Passwort
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="emailInput"
                  name="email"
                  value={imapPassword}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div
                className="alert alert-danger text-center mb-3"
                hidden={!hasFailedCredentials}
                role="alert"
              >
                Falsches Passwort
              </div>
              <div
                className="alert alert-danger text-center mb-3"
                hidden={!hasFailedUnknown}
                role="alert"
              >
                Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch
                einmal.
              </div>
              {submitInProgress && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="mb-3 spinner-border text-center"
                    role="status"
                  ></div>
                </div>
              )}
              <div className="mb-3 text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitInProgress}
                >
                  Emails aus {year} importieren
                </button>
              </div>
            </form>
          </>
        );
      }
      case "availableGmail": {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <div style={{ paddingBottom: 16 }}>
                  Um sich mit Ihrem Google-Konto zu verbinden, benötigen Sie ein
                  Gmail App-Passwort. Dieses können Sie auf der folgenden Seite
                  erstellen:
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: 16,
                    }}
                  >
                    <a
                      href="https://myaccount.google.com/apppasswords"
                      target="_blank"
                      rel="noreferrer"
                    >
                      App-Passwort erstellen
                    </a>
                  </div>
                </div>
                <label htmlFor="emailInput" className="form-label">
                  Gmail App-Passwort
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="emailInput"
                  name="email"
                  value={imapPassword}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div
                className="alert alert-danger text-center mb-3"
                hidden={!hasFailedCredentials}
                role="alert"
              >
                Falsches Passwort
              </div>
              <div
                className="alert alert-danger text-center mb-3"
                hidden={!hasFailedUnknown}
                role="alert"
              >
                Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch
                einmal.
              </div>
              {submitInProgress && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="mb-3 spinner-border text-center"
                    role="status"
                  ></div>
                </div>
              )}
              <div className="mb-3 text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitInProgress}
                >
                  Emails aus {year} importieren
                </button>
              </div>
            </form>
          </>
        );
      }
      case "availableOAuth": {
        return (
          <>
            <div
              className="alert alert-danger text-center mb-3"
              hidden={!hasFailedUnknown}
              role="alert"
            >
              Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch
              einmal.
            </div>
            <div className="mb-3 text-center">
              <a href={mailImportMethod.authUrl} className="btn btn-primary">
                Anmelden mit {mailImportMethod.emailProviderName}
              </a>
            </div>
          </>
        );
      }
      // TODO: For now we treat all kinds of not-supported as our fault. We
      // should try more things server-side (e.g. trying to contact a supposedly
      // insecure server via tls) before we blame the user's email provider.
      case "unsupportedByUs":
      case "unsupportedByThem":
      case "insecure": {
        return (
          <>
            <div className="alert alert-warning" role="alert">
              <p>
                Leider unterstützen wir das Einbinden Ihres Email-Postfachs
                {emailProviderName != null ? (
                  <span>
                    {" "}
                    bei <cite>{emailProviderName}</cite>{" "}
                  </span>
                ) : (
                  <span> </span>
                )}
                noch nicht. Wir arbeiten daran, also schauen Sie später noch
                einmal rein!
              </p>
              <p>
                In der Zwischenzeit können Sie die alternativen
                Importmöglichkeiten unten nutzen.
              </p>
            </div>
          </>
        );
      }
      default: {
        const exhaustive: never = mailImportMethod;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  return (
    <div>
      {statusView}
      {importView}
    </div>
  );
}
