import { MoneyAmount } from "./api/types";

export function formatPrice(
  value: MoneyAmount | number,
  currency?: string,
): string {
  // TODO: Is there a better way here?
  const numberValue: number =
    typeof value === "number" ? value : Number.parseFloat(value);

  if (currency !== undefined) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: currency,
    }).format(numberValue);
  } else {
    return new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberValue);
  }
}
