import "bootstrap/dist/css/bootstrap.css";
import { forwardRef, useState, useEffect, useRef, Ref, FormEvent } from "react";
import { DocumentInfo, DocumentKind } from "./api/types";

export type DocumentLinkProps = {
  documentInfo: DocumentInfo;
  useContentKindLabel: boolean;
};

function documentNameLabel(documentInfo: DocumentInfo): string {
  const detail = documentInfo.detail;
  switch (detail.documentType) {
    case "File": {
      const filePath = detail.filePath;
      const fileName: string | undefined = filePath.split("/").pop();
      return `🗎 ${fileName}`;
    }
    case "Mail": {
      const subject = detail.subject;
      return `✉ ${subject}`;
    }
  }
}

function contentKindLabel(documentInfo: DocumentInfo): string {
  if (!documentInfo.contentKind) {
    return documentNameLabel(documentInfo);
  }

  switch (documentInfo.contentKind) {
    case DocumentKind.OrderConfirmation:
      return "Bestellbestätigung";
    case DocumentKind.Invoice:
      return "Rechnung";
    case DocumentKind.Receipt:
      return "Quittung";
    case DocumentKind.ShippingConfirmation:
      return "Versandbestätigung";
    case DocumentKind.Other:
      return documentNameLabel(documentInfo);
    default: {
      const exhaustive = documentInfo.contentKind;
      throw new Error(`Unexpected content kind: ${exhaustive}`);
    }
  }
}

export const DocumentLink = forwardRef(function (
  { documentInfo, useContentKindLabel }: DocumentLinkProps,
  ref: Ref<HTMLAnchorElement>,
) {
  const documentUrl: string = `/api/document/${documentInfo.documentHash}`;
  const detail = documentInfo.detail;
  const label = useContentKindLabel
    ? contentKindLabel(documentInfo)
    : documentNameLabel(documentInfo);
  switch (detail.documentType) {
    case "File": {
      const filePath = detail.filePath;
      const fileName: string | undefined = filePath.split("/").pop();
      return (
        <a
          ref={ref}
          href={documentUrl}
          download={fileName}
          style={{ wordBreak: "break-all" }}
        >
          {label}
        </a>
      );
    }
    case "Mail": {
      const subject = detail.subject;
      return (
        <a ref={ref} href={documentUrl} download={`${subject}.pdf`}>
          {label}
        </a>
      );
    }
  }
});

async function uploadDocument(doc: File): Promise<DocumentInfo[]> {
  const formData = new FormData();
  formData.append("document", doc);
  try {
    const response = await fetch("/api/document", {
      method: "POST",
      body: formData,
      headers: {
        "Last-Modified": new Date(doc.lastModified).toUTCString(),
      },
    });
    if (response.status !== 200 /* OK */) {
      throw new Error(`Status ${response.status}`);
    }
    return response.json();
  } catch (err) {
    throw new Error(`Failed to upload file ${doc}: ${err}`);
  }
}

export type DocumentUploadProgressProps = {
  documents: File[];
  onNewUploads: (infos: DocumentInfo[]) => void;
};

export function DocumentUploadProgress({
  documents,
  onNewUploads,
}: DocumentUploadProgressProps) {
  const [uploadedDocuments, setUploadedDocuments] = useState(
    new Set() as Set<File>,
  );
  const [failedDocuments, setFailedDocuments] = useState(
    new Set() as Set<File>,
  );
  const [uploadingDocument, setUploadingDocument] = useState(
    null as File | null,
  );

  useEffect(() => {
    (async function () {
      if (uploadingDocument != null) {
        return;
      }

      const nextDoc = documents.find(
        (doc) => !uploadedDocuments.has(doc) && !failedDocuments.has(doc),
      );
      if (!nextDoc) {
        return;
      }

      setUploadingDocument(nextDoc);
      try {
        const newUploads = await uploadDocument(nextDoc);
        setUploadedDocuments((prev) => new Set(prev).add(nextDoc));
        onNewUploads(newUploads);
      } catch (err) {
        console.error(err);
        setFailedDocuments((prev) => new Set(prev).add(nextDoc));
        return;
      } finally {
        setUploadingDocument(null);
      }
    })();
  }, [
    documents,
    uploadedDocuments,
    uploadingDocument,
    failedDocuments,
    onNewUploads,
  ]);

  let rows = [];

  for (const doc of documents) {
    let state = null;
    if (uploadedDocuments.has(doc)) {
      state = "✓";
    } else if (failedDocuments.has(doc)) {
      state = "x";
    } else if (uploadingDocument === doc) {
      state = (
        <span className="spinner-border spinner-border-sm" role="status"></span>
      );
    }

    rows.push(
      <tr>
        <td style={{ width: "1.5rem" }} className="text-center">
          {state}
        </td>
        <td style={{ wordBreak: "break-all" }}>{doc.name}</td>
      </tr>,
    );
  }

  return (
    <table>
      <tbody>{rows}</tbody>
    </table>
  );
}

export type DocumentUploadProps = {
  onNewUploads: (infos: DocumentInfo[]) => void;
};

export function DocumentUpload({ onNewUploads }: DocumentUploadProps) {
  const [documents, setDocuments] = useState([] as File[]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  function selectedFiles(): FileList {
    const fileInput = fileInputRef.current;

    // This will err if fileInputRef.current == null, so this function must not
    // be used during the first render.
    if (!(fileInput instanceof HTMLInputElement)) {
      throw new Error(`Unexpected fileInput element: ${fileInput}`);
    }
    if (!(fileInput.type === "file")) {
      throw new Error(`Unexpected fileInput type: ${fileInput.type}`);
    }

    if (fileInput.files == null) {
      throw new Error(`file input should have files attribute`);
    }

    return fileInput.files;
  }

  const [disableSubmit, setDisableSubmit] = useState(true);
  function onFileInputChange() {
    setDisableSubmit(selectedFiles().length === 0);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const newFiles = Array.from(selectedFiles());

    // This resets a file input:
    const fileInput = fileInputRef.current;
    if (fileInput === null) {
      throw new Error("fileInputRef should be bound");
    }
    fileInput.value = "";

    // Resetting the fileInput above appers to not fire an onChange event, so
    // we need to call the handler ourselves.
    onFileInputChange();
    setDocuments((prev) => prev.concat(newFiles));
  }

  return (
    <div className="row">
      <div className="col" style={{ minWidth: "400px" }}>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="documents" className="form-label">
              Emails, Rechnungen, ... (<samp>.pdf, .zip, .eml</samp>)
            </label>
            <input
              ref={fileInputRef}
              className="form-control"
              type="file"
              id="documents"
              name="documents"
              multiple
              onChange={onFileInputChange}
            />
          </div>
          <div className="mb-3 text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={disableSubmit}
            >
              Hochladen
            </button>
          </div>
        </form>
      </div>
      <div className="col mb-3" style={{ minWidth: "400px" }}>
        <DocumentUploadProgress
          documents={documents}
          onNewUploads={onNewUploads}
        />
      </div>
    </div>
  );
}
