import { useEffect, useState } from "react";
import { MoneyAmount } from "./api/types";
import { formatPrice } from "./format-price";

export type MoneyAmountInputProps = {
  defaultValue: string;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
};

export function parseMoneyAmount(value: string): MoneyAmount | null {
  // This regex:
  // - Matches 0 or more digits, optionally separated by dots to group thousands.
  //   We don't enforce that the dots are in the right place, because we want to allow editing.
  // - Then, optionally, matches a comma followed by 0 to 2 digits.
  const regex = /^([\d.]*)(?:,(\d{0,2}))?$/;
  const match = value.match(regex);
  if (match == null) {
    return null;
  }

  const integralWithoutGrouping = match[1].replace(/\./g, "");
  const integral =
    integralWithoutGrouping.length === 0 ? 0 : Number(integralWithoutGrouping);

  const fractionalString = match[2];

  const fractionalHundredths = (() => {
    switch (fractionalString?.length) {
      case undefined:
      case 0:
        return 0;
      case 1:
        return Number(fractionalString) * 10;
      case 2:
        return Number(fractionalString);
      default:
        // This should never happen given the regex above.
        throw new Error(
          `Fractional part can only be 0, 1 or 2 digits long: ${fractionalString}`,
        );
    }
  })();

  return (integral + fractionalHundredths / 100).toFixed(2);
}

export function MoneyAmountInput({
  defaultValue,
  onBlur,
  onKeyPress,
  style,
}: MoneyAmountInputProps): JSX.Element {
  useEffect(() => {
    if (!parseMoneyAmount(defaultValue)) {
      throw new Error(`Invalid default value: ${defaultValue}`);
    }
  }, [defaultValue]);

  const [value, setValue] = useState(defaultValue);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (parseMoneyAmount(event.target.value) == null) {
      return;
    }

    setValue(event.target.value);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    // Normalize the value to the format we expect: Thousands separated by dots and two decimal
    // places.
    const parsedValue = parseMoneyAmount(value);
    if (parsedValue == null) {
      throw new Error(`Invariant violated, invalid value: ${value}`);
    }

    setValue(formatPrice(parsedValue));
    onBlur?.(event);
  }

  return (
    <input
      className="form-control"
      type="text"
      value={value}
      onBlur={handleBlur}
      onKeyPress={onKeyPress}
      onChange={handleChange}
      style={style}
    />
  );
}
