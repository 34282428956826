import { MoneyAmountInput, parseMoneyAmount } from "./money-value-input";
import { Popover, Overlay } from "react-bootstrap";
import { DocumentLink } from "./document";
import { deleteExpense, updateExpense } from "./api/expense";
import {
  ExpenseInfo,
  MoneyAmount,
  CURRENCIES,
  Currency,
  shortCurrencyString,
  DataSource,
} from "./api/types";
import { createRef, useEffect, useState } from "react";
import { formatPrice } from "./format-price";

const NAME_COL_STYLE = { width: "100%" };
const DEDUCT_COL_STYLE = { minWidth: "80px" };
const EXPAND_COL_STYLE = { width: "10px" };

function totalDeductAmounts(
  expenseInfos: Array<ExpenseInfo>,
): Map<Currency, MoneyAmount> {
  const deductAmounts = new Map<Currency, MoneyAmount>();
  for (const expenseInfo of expenseInfos) {
    const { expense, workPercentage } = expenseInfo;
    const { currency } = expense;
    if (currency == null || workPercentage == null || workPercentage === 0) {
      continue;
    } else {
      const deduct = deductAmount(expense.amount, workPercentage);
      const current = deductAmounts.get(currency);

      if (current != null) {
        deductAmounts.set(
          currency,
          (Number.parseFloat(current) + Number.parseFloat(deduct)).toFixed(2),
        );
      } else {
        deductAmounts.set(currency, deduct);
      }
    }
  }

  return deductAmounts;
}

type ExpenseRowProps = {
  expenseInfo: ExpenseInfo;
  setExpenseInfo: (expenseInfo: ExpenseInfo | undefined) => void;
  year: number | null;
};

function blurOnEnter<T extends HTMLElement>(ev: React.KeyboardEvent<T>) {
  if (ev.key === "Enter") {
    if (!(ev.target instanceof HTMLElement)) {
      throw new Error("This handler must only be installed on HTMLElements");
    }
    ev.target.blur();
  }
}

function deductAmount(
  amount: MoneyAmount,
  workPercentage: number,
): MoneyAmount {
  // TODO: Use fractional math -- this uses floating point, which is not suitable for financial
  // data.
  const newNumberValue: number =
    Number.parseFloat(amount) * (workPercentage / 100);
  // Format newNumberValue with 2 decimal places.
  return newNumberValue.toFixed(2);
}

export function ExpenseRow({
  expenseInfo,
  setExpenseInfo,
  year,
}: ExpenseRowProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsFirstRender(false);
    }, 0);
  }, []);

  const { expense, receipts, workPercentage } = expenseInfo;
  const { name, amount, currency, date, vendor } = expense;

  const cellBorderStyle: React.CSSProperties = {
    borderBottom: isExpanded ? "0" : undefined,
    backgroundColor: isExpanded ? "#f8f9fa" : undefined,
  };
  const detailsCellStyle: React.CSSProperties = {
    backgroundColor: "#f8f9fa", // Light-grey
  };
  const expandedRowStyle: React.CSSProperties = isExpanded
    ? {
        borderBottom: "solid",
        borderBottomWidth: "0.6666666px",
      }
    : {};

  const [isEditingName, setIsEditingName] = useState(false);

  const workPercentageControl = createRef<HTMLInputElement>();
  const dateControl = createRef<HTMLInputElement>();

  const [showWorkPercentagePopover, setShowWorkPercentagePopover] =
    useState(false);

  function toggleIsExpanded() {
    const nextExpanded = !isExpanded;
    setIsExpanded(nextExpanded);
    if (!nextExpanded) {
      setIsEditingName(false);
    }
  }

  function handleNameBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newName = ev.target.value;
    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        name: newName,
      },
    });
    setIsEditingName(false);
  }

  function handleDateBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newDate = new Date(ev.target.value);
    // Format date in "yyyy-MM-dd" format.
    const newDateString = newDate.toISOString().split("T")[0];
    const newYear = new Date(newDate).getFullYear();

    // TODO: Check that date is actually a valid date.

    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        date: newDateString,
      },
    });
  }

  function handleVendorBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newVendor = ev.target.value;
    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        vendor: newVendor,
      },
    });
  }

  function handleAmountBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newAmount = parseMoneyAmount(ev.target.value);
    console.log(newAmount);
    if (newAmount == null) {
      // This should be impossible by the contract of MoneyAmountInput; perhaps we should encode
      // it in the type system.
      throw new Error(
        `Invalid amount does not parse to MoneyAmount: ${newAmount}`,
      );
    }

    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        amount: newAmount,
      },
    });
  }

  function handleCurrencyBlur(ev: React.FocusEvent<HTMLSelectElement>) {
    const newCurrency: Currency | undefined = CURRENCIES.find(
      (cur) => cur === ev.target.value,
    );
    if (newCurrency == null) {
      throw new Error(`Invalid value of currency select: ${ev.target.value}`);
    }

    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        currency: newCurrency,
      },
    });
  }

  function handleWorkPercentageBlurOrClick(
    ev: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>,
  ) {
    const target = ev.target as HTMLInputElement;
    const value = target.value;
    const newWorkPercentage: number = Number.parseInt(value);
    if (
      !Number.isInteger(newWorkPercentage) ||
      newWorkPercentage < 0 ||
      newWorkPercentage > 100
    ) {
      console.log(`Invalid value of work percentage: ${value}`);
      target.classList.add("is-invalid");
      return;
    } else {
      target.classList.remove("is-invalid");
    }

    if (
      (workPercentage == null || workPercentage === 0) &&
      newWorkPercentage > 0
    ) {
      setShowWorkPercentagePopover(true);
      return;
    }

    if (
      workPercentage != null &&
      workPercentage > 0 &&
      newWorkPercentage === 0
    ) {
      setShowWorkPercentagePopover(true);
      return;
    }

    setExpenseInfo({
      ...expenseInfo,
      workPercentage: newWorkPercentage,
    });
  }

  function handleWorkPercentagePopoverCancel() {
    if (workPercentageControl.current == null) {
      throw new Error(
        "workPercentageControl is not rendered even though the popover is shown.",
      );
    }
    workPercentageControl.current.value =
      workPercentage == null ? "" : workPercentage.toString();
    setShowWorkPercentagePopover(false);
  }

  function handleWorkPercentagePopoverOkClick(
    ev: React.MouseEvent<HTMLButtonElement>,
  ) {
    if (workPercentageControl.current == null) {
      throw new Error(
        "workPercentageControl is not rendered even though the popover is shown.",
      );
    }
    const newWorkPercentage: number = Number.parseInt(
      workPercentageControl.current.value,
    );

    setShowWorkPercentagePopover(false);

    // TODO: This is duplciated in handleWorkPercentageBlur. Refactor to a common function.
    setExpenseInfo({
      ...expenseInfo,
      workPercentage: newWorkPercentage,
    });
  }

  function handleDeleteClick() {
    setExpenseInfo(undefined);
  }

  const opacityHeightStyle = isFirstRender ? { opacity: 0 } : { opacity: 1 };

  return (
    <>
      <tr
        style={{
          ...cellBorderStyle,
          ...opacityHeightStyle,
          transition: "opacity 0.3s ease",
        }}
      >
        <td
          style={{ ...EXPAND_COL_STYLE, ...cellBorderStyle }}
          onClick={toggleIsExpanded}
        >
          <button className="btn" style={{ border: "none" }}>
            {isExpanded ? "⌃" : "⌄"}
          </button>
        </td>
        <td
          style={{ ...NAME_COL_STYLE, ...cellBorderStyle }}
          onClick={isEditingName ? undefined : toggleIsExpanded}
        >
          {!isExpanded ? (
            <span>{name}</span>
          ) : !isEditingName ? (
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
              }}
            >
              {name}{" "}
              <button
                className="btn"
                onClick={(ev) => {
                  // If we wouldn't stop propagation here, it would trigger the onClick listener of
                  // the parent <tr> element, which toggles the expanded view.
                  ev.stopPropagation();
                  setIsEditingName(true);
                }}
              >
                📝
              </button>
            </div>
          ) : (
            <input
              type="text"
              autoFocus
              className="form-control"
              defaultValue={name}
              onBlur={handleNameBlur}
              onKeyPress={blurOnEnter}
              style={{ width: "100%" }}
            />
          )}
        </td>
        <td
          style={{ ...DEDUCT_COL_STYLE, ...cellBorderStyle }}
          onClick={toggleIsExpanded}
        >
          <div>
            {formatPrice(
              workPercentage === 0 || workPercentage == null
                ? amount
                : deductAmount(amount, workPercentage),
              currency,
            )}
          </div>

          {workPercentage != null &&
            workPercentage > 0 &&
            workPercentage < 100 && (
              <div style={{ color: "grey" }}>
                {workPercentage} % von {formatPrice(amount, undefined)}
              </div>
            )}
        </td>
      </tr>
      {isExpanded && (
        // Light-grey background:
        <tr style={expandedRowStyle}>
          <td style={{ ...cellBorderStyle, ...detailsCellStyle }}></td>
          <td style={{ ...cellBorderStyle, ...detailsCellStyle }} colSpan={3}>
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Preis:</label>
              <div
                className="col-sm-8"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <MoneyAmountInput
                  defaultValue={formatPrice(amount)}
                  onKeyPress={blurOnEnter}
                  onBlur={handleAmountBlur}
                  style={{ width: "100px" }}
                />
                <select
                  defaultValue={currency}
                  className="form-select"
                  onBlur={handleCurrencyBlur}
                  style={{ maxWidth: "max-content" }}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <option key={currency} value={currency}>
                        {shortCurrencyString(currency)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Arbeitsanteil:</label>
              <div className="col-sm-8">
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    maxWidth: "90px",
                  }}
                >
                  {/* There's no blur event when clicking the up/down buttons on the input type=number.
                      As an easy hacky solution, we also listen for click events.
                    */}
                  <input
                    type="number"
                    ref={workPercentageControl}
                    className="form-control"
                    defaultValue={
                      workPercentage == null ? undefined : workPercentage
                    }
                    onBlur={handleWorkPercentageBlurOrClick}
                    onClick={handleWorkPercentageBlurOrClick}
                    min="0"
                    max="100"
                    step="10"
                    required
                    style={{ width: "100%", paddingRight: "20px" }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: 0,
                      bottom: 0,
                      lineHeight: "38px",
                      pointerEvents: "none",
                    }}
                  >
                    %
                  </span>
                </div>
                <Overlay
                  target={workPercentageControl}
                  show={showWorkPercentagePopover}
                  placement="bottom"
                >
                  <Popover
                    id="popover-contained"
                    onBlur={handleWorkPercentagePopoverCancel}
                  >
                    <Popover.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {(workPercentage == null || workPercentage === 0) && (
                        <div>Ausgabe zu beruflichen Ausgaben verschieben?</div>
                      )}
                      {workPercentage === 100 && (
                        <div>Ausgabe zu privaten Ausgaben verschieben?</div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          justifyContent: "end",
                        }}
                      >
                        <button
                          className="btn btn-secondary"
                          onClick={handleWorkPercentagePopoverCancel}
                        >
                          Abbrechen
                        </button>
                        <button
                          className="btn btn-primary"
                          autoFocus
                          onClick={handleWorkPercentagePopoverOkClick}
                        >
                          OK
                        </button>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Datum:</label>
              <div className="col-sm-8">
                <input
                  type="date"
                  className="form-control"
                  defaultValue={date}
                  onKeyPress={blurOnEnter}
                  onBlur={handleDateBlur}
                  ref={dateControl}
                  style={{ maxWidth: "max-content" }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Händler:</label>
              <div className="col-sm-8">
                <input
                  className="form-control col-sm-8"
                  onKeyPress={blurOnEnter}
                  onBlur={handleVendorBlur}
                  type="text"
                  defaultValue={vendor}
                  style={{ maxWidth: "300px" }}
                />
              </div>
            </div>

            {/*
              TODO: We need to generate dummy receipts to show here.
            <div className="row mb-3">
              <label className="col-sm-4 col-form-label">Belege:</label>
              <div className="col-sm-8">
                <ul style={{ paddingLeft: "20px" }}>
                  {receipts.map((receipt) => (
                    <li key={receipt.documentHash}>
                      <DocumentLink
                        documentInfo={receipt}
                        useContentKindLabel={true}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <button className="btn btn-danger" onClick={handleDeleteClick}>
                Löschen
              </button>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

const dummyExpenses: ExpenseInfo[] = [
  {
    expenseId: 1,
    expense: {
      name: "Lehrbuch Mathematik 8. Klasse",
      vendor: "Schulbuchhandlung Müller",
      date: "2023-01-15",
      amount: "39.99",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 4,
      reasoning: "Beruflich erforderlich für den Unterricht",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 2,
    expense: {
      name: "Schreibtischstuhl Ergonom",
      vendor: "Bürobedarf Schmidt",
      date: "2023-02-10",
      amount: "129.90",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 3,
      reasoning: "Für das Arbeitszimmer genutzt",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 3,
    expense: {
      name: "Farbpatronen Set",
      vendor: "Office Supplies Express",
      date: "2023-03-05",
      amount: "45.50",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 3,
      reasoning: "Benötigt für Drucker im Arbeitszimmer",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 4,
    expense: {
      name: 'Fortbildung "Moderne Lehrmethoden"',
      vendor: "Online Akademie",
      date: "2023-04-20",
      amount: "250.00",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 5,
      reasoning: "Notwendig für berufliche Weiterbildung",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 5,
    expense: {
      name: "Schreibtischlampe LuxLight 2023",
      vendor: "Home & Office",
      date: "2023-05-15",
      amount: "79.95",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 3,
      reasoning: "Ergonomische Beleuchtung für Arbeitszimmer",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 6,
    expense: {
      name: "Urlaubsreise nach Spanien",
      vendor: "Reisebüro Happy Travels",
      date: "2023-06-01",
      amount: "1200.00",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 0,
      reasoning: "Privater Urlaub",
    },
    workPercentage: 0,
    dataSource: DataSource.User,
  },
  {
    expenseId: 7,
    expense: {
      name: "Fitnessstudio Jahresmitgliedschaft",
      vendor: "Fit & Fun Gym",
      date: "2023-07-01",
      amount: "499.99",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 0,
      reasoning: "Private Gesundheitspflege",
    },
    workPercentage: 0,
    dataSource: DataSource.User,
  },
  {
    expenseId: 8,
    expense: {
      name: "Tablet Pro 12.9 inch",
      vendor: "TechStore International",
      date: "2023-08-15",
      amount: "1099.99",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 4,
      reasoning: "Nützlich für digitalen Unterricht und Planung",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 9,
    expense: {
      name: "Software MathSolver 13",
      vendor: "EduTech Software",
      date: "2023-09-10",
      amount: "350.00",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 5,
      reasoning: "Erforderlich für Unterrichtsvorbereitung",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 10,
    expense: {
      name: 'Fachzeitschrift Abo "Mathematik aktuell"',
      vendor: "Zeitschriften Verlag",
      date: "2023-10-01",
      amount: "120.00",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 4,
      reasoning: "Fortbildung und aktuelle Entwicklungen im Fachbereich",
    },
    workPercentage: 100,
    dataSource: DataSource.User,
  },
  {
    expenseId: 11,
    expense: {
      name: "Konzertkarten Kaltspiel",
      vendor: "Eventim",
      date: "2023-11-05",
      amount: "150.00",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 0,
      reasoning: "Private Freizeitaktivität",
    },
    workPercentage: 0,
    dataSource: DataSource.User,
  },
  {
    expenseId: 12,
    expense: {
      name: "Kaffeemaschine Barista Pro",
      vendor: "Haus & Heim",
      date: "2023-12-01",
      amount: "299.00",
      currency: Currency.EUR,
    },
    receipts: [],
    deductibility: {
      rating: 0,
      reasoning: "Privater Haushaltsgegenstand",
    },
    workPercentage: 0,
    dataSource: DataSource.User,
  },
];

function sampleExponential(): number {
  const rate = 3;

  const u = Math.random();
  // Apply the inverse transform sampling method.
  return -Math.log(u) / rate;
}

function getRandomNumbersInRange(range: number, count: number): number[] {
  const numbers: number[] = [];
  for (let i = 0; i < range; i++) {
    numbers.push(i);
  }

  for (let i = numbers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
  }

  return numbers.slice(0, count);
}

const dummyDocumentNum = 538;
const dummyExpenseDocumentNums = getRandomNumbersInRange(
  100, // We want to show the expenses early on.
  dummyExpenses.length,
);

export function LandingPageDemo(): JSX.Element {
  const [analyzedNum, setAnalyzedNum] = useState(0);
  const [expenses, setExpenses] = useState([] as Array<ExpenseInfo>);

  useEffect(() => {
    if (analyzedNum < dummyDocumentNum) {
      const interval = setTimeout(() => {
        const newExpenseIndex = dummyExpenseDocumentNums.indexOf(analyzedNum);
        if (newExpenseIndex !== -1) {
          setExpenses([...expenses, dummyExpenses[newExpenseIndex]]);
        }
        setAnalyzedNum(analyzedNum + 1);
      }, sampleExponential() * 1000);

      return () => clearTimeout(interval);
    }
  }, [analyzedNum]);

  const deductibleExpenses = [];
  const nonDeductibleExpenses = [];
  for (const expenseInfo of expenses) {
    if (
      expenseInfo.workPercentage == null ||
      expenseInfo.workPercentage === 0
    ) {
      nonDeductibleExpenses.push(expenseInfo);
    } else {
      deductibleExpenses.push(expenseInfo);
    }
  }

  // Try to maintain a stable sort order among deductible expenses by sorting by expenseId.
  deductibleExpenses.sort((a, b) => {
    if (a.expenseId === b.expenseId) {
      return 0;
    }

    return a.expenseId < b.expenseId ? 1 : -1;
  });

  // We sort non deductible expenses as follows:
  // - Deductibility rating: Highest first, since we want to show the expenses that were
  //   misclassified as private first.
  // - ExpenseId: To maintain a stable sort order otherwise.
  nonDeductibleExpenses.sort((a, b) => {
    if (a.deductibility != null && b.deductibility != null) {
      if (a.deductibility.rating !== b.deductibility.rating) {
        return a.deductibility.rating > b.deductibility.rating ? 1 : -1;
      }
    } else {
      if (a.deductibility == null && b.deductibility != null) {
        return 1;
      }
      if (a.deductibility != null && b.deductibility == null) {
        return -1;
      }
    }

    if (a.expenseId === b.expenseId) {
      return 0;
    }

    return a.expenseId < b.expenseId ? 1 : -1;
  });

  const totalDeduct = Array.from(totalDeductAmounts(expenses));

  return (
    <div
      style={{
        backgroundColor: "#f8f4ee",
        padding: 16,
        borderRadius: 8,
        fontFamily: "sans",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid",
          padding: 16,
          borderRadius: 8,
          height: 400,
          overflowY: "auto",
        }}
      >
        {analyzedNum < dummyDocumentNum && (
          <div>
            <span style={{ color: "grey" }}>
              Dokumente werden analysiert<span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span> ({analyzedNum}/{dummyDocumentNum})
            </span>
          </div>
        )}

        {deductibleExpenses.length > 0 && (
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <h2>Absetzbare Ausgaben</h2>
            </div>

            <table className="table">
              <tbody>
                {totalDeduct.length >= 1 && (
                  <tr style={{ fontWeight: "bold" }}>
                    <td colSpan={2}>Gesamt</td>
                    <td>
                      {totalDeduct.map(([currency, amount]) => (
                        <div key={currency}>
                          <strong>{formatPrice(amount, currency)}</strong>
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
                {deductibleExpenses.map((expenseInfo) => (
                  <ExpenseRow
                    year={2023}
                    key={expenseInfo.expenseId}
                    expenseInfo={expenseInfo}
                    setExpenseInfo={(newExpenseInfo) => {
                      if (
                        newExpenseInfo != null &&
                        newExpenseInfo.expenseId !== expenseInfo.expenseId
                      ) {
                        throw new Error("setExpenseInfo called with wrong ID");
                      }

                      setExpenses(
                        newExpenseInfo == null
                          ? expenses.filter(
                              (oldExpenseInfo) =>
                                oldExpenseInfo.expenseId !==
                                expenseInfo.expenseId,
                            )
                          : expenses.map((oldExpenseInfo) =>
                              oldExpenseInfo.expenseId ===
                              newExpenseInfo.expenseId
                                ? newExpenseInfo
                                : oldExpenseInfo,
                            ),
                      );
                    }}
                  />
                ))}
              </tbody>
            </table>
          </>
        )}

        {nonDeductibleExpenses.length > 0 && (
          <>
            <h2>Private Ausgaben</h2>
            <table className="table">
              <tbody>
                {nonDeductibleExpenses.map((expenseInfo) => (
                  <ExpenseRow
                    key={expenseInfo.expenseId}
                    year={2023}
                    expenseInfo={expenseInfo}
                    setExpenseInfo={(newExpenseInfo) => {
                      if (
                        newExpenseInfo != null &&
                        newExpenseInfo.expenseId !== expenseInfo.expenseId
                      ) {
                        throw new Error("setExpenseInfo called with wrong ID");
                      }

                      setExpenses(
                        newExpenseInfo == null
                          ? expenses.filter(
                              (oldExpenseInfo) =>
                                oldExpenseInfo.expenseId !==
                                expenseInfo.expenseId,
                            )
                          : expenses.map((oldExpenseInfo) =>
                              oldExpenseInfo.expenseId ===
                              newExpenseInfo.expenseId
                                ? newExpenseInfo
                                : oldExpenseInfo,
                            ),
                      );
                    }}
                  />
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
