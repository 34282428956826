type GetPayload = {
  jobDescription?: string;
};

export async function getJobDescription(
  year: number,
): Promise<string | undefined> {
  const url = `/api/job-description/${year}`;
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }

    const { jobDescription }: GetPayload = await response.json();
    return jobDescription;
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}

type PostPayload = {
  jobDescription: string;
};

export async function postJobDescription(
  year: number,
  jobDescription: string,
): Promise<void> {
  const payload: PostPayload = { jobDescription };
  const response = await fetch(`/api/job-description/${year}`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  });

  if (response.status !== 200 /* OK */) {
    throw new Error(`Failed to set job description: ${response}`);
  }
}
