import "bootstrap/dist/css/bootstrap.css";
import "./landing.css";
import showcaseHandsInControl from "./showcase-hands-in-control.jpg";
import showcaseHandsOverBook from "./showcase-hands-over-book.jpg";
import showcaseScreenshot from "./showcase-screenshot.jpg";
import showcaseDocumentPile from "./showcase-document-pile.jpg";
import { Link } from "wouter";
import { RouteKind, serializeRoute } from "./route";
import bgMasthead from "./bg-masthead.jpg";
import guyWithDocumentsGetsRobotHelp from "./guy-with-documents-gets-robot-help.jpg";
import relaxedGuy from "./relaxed-guy.jpg";
import { LandingPageDemo } from "./landing-page-demo";

const imageShowCasesFirst = (
  <section className="showcase">
    <div
      className="container-fluid p-0"
      style={{ display: "flex", flexDirection: "column", gap: 32 }}
    >
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-2"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Ihre persönliche Werbungskostenanalyse</h2>
          <p className="lead mb-0">
            Egal ob Angestellter oder Freiberufler – unsere intelligente AI
            versteht Ihre berufliche Situation und findet zielsicher alle
            absetzbaren Ausgaben. So holen Sie das Maximum aus Ihrer
            Steuererklärung heraus.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-1"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${showcaseHandsOverBook})` }}
          ></div>
        </div>
      </div>
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-1"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Stressfrei zur Steuererstattung</h2>
          <p className="lead mb-0">
            Machen Sie sich keine Sorgen mehr um Ihre Steuererklärung. Wir
            helfen Ihnen, den maximalen Steuerabzug zu erzielen ohne dabei den
            Kopf zu verlieren.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-2"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${relaxedGuy})` }}
          ></div>
        </div>
      </div>
    </div>
  </section>
);

const imageShowCasesSecond = (
  <section className="showcase">
    <div
      className="container-fluid p-0"
      style={{ display: "flex", flexDirection: "column", gap: 32 }}
    >
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-2"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Verbinden mit Email-Postfach</h2>
          <p className="lead mb-0">
            Ihr Email-Postfach enthält Belege für die Steuererklärung, aber auch
            viel Unwichtiges.
          </p>
          <p className="lead mb-0">
            Kein Problem: Werbungskostenrechner.de findet automatisch die
            richtigen Dokumente.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-1"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${showcaseDocumentPile})` }}
          ></div>
        </div>
      </div>
      <div className="row g-0">
        <div
          className="col-lg-6 order-lg-1"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <h2>Sie bestimmen</h2>

          <p className="lead mb-0">
            Ihre Privatsphäre ist uns wichtig. Deshalb behalten Sie die volle
            Kontrolle darüber, welche Dokumente und Emails analysiert werden.
          </p>
          <p className="lead mb-0">
            Statt Ihr gesamtes Email-Postfach einzubinden, können Sie auch nur
            bestimme Dokumente Ihrer Wahl direkt hochladen oder ausgewählte
            Emails an uns weiterleiten.
          </p>
        </div>
        <div
          className="col-lg-6 order-lg-2"
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <div
            className="showcase-img"
            style={{ backgroundImage: `url(${showcaseHandsInControl})` }}
          ></div>
        </div>
      </div>
    </div>
  </section>
);

function HeadlineAndDemo(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "min(100%, 1024px)",
      }}
    >
      <div
        className="container-fluid p-0"
        style={{ display: "flex", flexDirection: "column", gap: 32 }}
      >
        <div className="row g-0">
          <div
            className="col-lg-6 order-lg-1"
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 16,
            }}
          >
            <h1 style={{ fontSize: "3rem", fontWeight: "500" }}>
              Nie wieder Rechnungen suchen!
            </h1>
            <p style={{ fontSize: "1.5rem" }}>
              Unsere intelligenten Algorithmen finden und bewerten steuerlich
              absetzbare Ausgaben für Sie.
            </p>
            <p>
              <Link
                href={serializeRoute({ kind: RouteKind.Signup })}
                className="btn btn-dark rounded-pill"
                style={{
                  fontSize: "2rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                Kostenlos Testen
              </Link>
            </p>
          </div>
          <div
            className="col-lg-6 order-lg-2"
            style={{ paddingLeft: 8, paddingRight: 8 }}
          >
            <LandingPageDemo />
          </div>
        </div>
      </div>
    </div>
  );
}

function UserStory(): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#e3d5ba",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          fontSize: "1.5rem",
          alignItems: "center",
        }}
      >
        <p>
          Die Steuererklärung steht an, die Zeit wird knapp. Das Finanzamt und
          der Steuerberater sind Ihnen auf den Fersen: Wo bleiben die
          Unterlagen?
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={guyWithDocumentsGetsRobotHelp}
            style={{ width: "max(500px, 80%)", height: "auto" }}
          />
        </div>

        <p>
          Unser AI-gestützter Service durchsucht Ihre Dokumente nach Belegen für
          steuerlich absetzbaren Ausgaben, damit Sie das nicht tun müssen. So
          ersparen Sie sich viel Arbeit, vergessen nichts und Ihre Unterlagen
          sind fristgerecht beim Finanzamt!
        </p>
      </div>
    </div>
  );
}

function HowItWorks(): JSX.Element {
  return (
    <div
      style={{
        fontSize: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f8f4ee",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          fontSize: "1.5rem",
          alignItems: "center",
        }}
      >
        <h2>So funktioniert es</h2>

        <ol>
          <li>
            <strong>Erklären:</strong> Beschreiben Sie kurz Ihren Beruf. So kann
            unsere AI besser verstehen, welche Ausgaben für Sie absetzbar sind.
          </li>
          <li>
            <strong>Verbinden:</strong> Verknüpfen Sie Ihr Email-Postfach oder
            laden Sie Ihren Dokumentenordner direkt hoch.
          </li>
          <li>
            <strong>Analysieren:</strong> Unsere intelligente AI durchsucht nun
            Ihre Dokumente für Sie nach steuerlich absetzbaren Ausgaben.
          </li>
          <li>
            <strong>Geld zurück bekommen:</strong> Tragen Sie die gefundenen
            Ausgaben in Ihre Steuererklärung ein und bekommen Sie Ihr Geld
            zurück!
          </li>
        </ol>
      </div>
    </div>
  );
}

function Conclusion(): JSX.Element {
  return (
    <div
      style={{
        fontSize: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f8f4ee",
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          fontSize: "1.5rem",
          alignItems: "center",
        }}
      >
        <h2>Steuern sparen mit Werbungskostenrechner.de</h2>

        <p>Die schnellste Art, Ihre Steuererklärung zu optimieren.</p>
        <Link
          href={serializeRoute({ kind: RouteKind.Signup })}
          className="btn btn-dark rounded-pill"
          style={{
            fontSize: "2rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          Kostenlos Testen
        </Link>
      </div>
    </div>
  );
}

function Landing() {
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        paddingTop: "32px",
        paddingBottom: "16px",
        fontFamily: '"Source Serif 4", serif',
      }}
    >
      <HeadlineAndDemo />

      <UserStory />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "min(100%, 1024px)",
        }}
      >
        {imageShowCasesFirst}
      </div>

      <HowItWorks />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "min(100%, 1024px)",
        }}
      >
        {imageShowCasesSecond}
      </div>

      <Conclusion />
    </main>
  );
}

export default Landing;
