import { ExpenseInfo, MoneyAmount } from "./types";

export async function getExpense(
  expenseId: string,
): Promise<ExpenseInfo | undefined> {
  const url = `/api/expense/${expenseId}`;
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }

    return await response.json();
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}

export type ExpenseUpdate = {
  name?: string;
  // A formatted decimal number with up to 2 fractional digits.
  amount?: MoneyAmount;
  // An ISO 4217 currency value.
  currency?: string;
  vendor?: string;
  // %Y-%m-%d string.
  date?: string;
  workPercentage?: number;
};

export async function updateExpense(
  expenseId: number,
  update: ExpenseUpdate,
): Promise<void> {
  const url = `/api/expense/${expenseId}`;
  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(update),
    });

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}

export async function deleteExpense(expenseId: number): Promise<void> {
  const url = `/api/expense/${expenseId}`;
  try {
    const response = await fetch(url, { method: "DELETE" });

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}
