import { FormEvent, useRef, useState } from "react";
import { ExpensesStatus } from "./api/expenses";
import { postJobDescription } from "./api/job-description";

export type ProfessionProps = {
  year: number;
  jobDescription: string;
  setJobDescription: (jobDescription: string) => void;
  setExpensesStatus(status: ExpensesStatus): void;
  onDone: () => void;
};

export function Profession({
  year,
  jobDescription,
  setJobDescription,
  setExpensesStatus,
  onDone,
}: ProfessionProps) {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  function handleSubmit(ev: FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const textArea = textAreaRef.current;
    if (textArea == null) {
      // Since handleSubmit can only be called via an event on the rendered DOM,
      // we must've rendered at least once when this is called.
      throw new Error("Logic error: textArea is null in handleSubmit");
    }

    const jobDescriptionValue: string = textArea.value;

    (async function () {
      setSubmitInProgress(true);
      try {
        setSubmitFailed(false);
        await postJobDescription(year, jobDescriptionValue);
        setJobDescription(jobDescriptionValue);
        setExpensesStatus(ExpensesStatus.Processing);
        onDone();
      } catch (err) {
        setSubmitFailed(true);
        throw new Error("Saving job description failed", { cause: err });
      } finally {
        setSubmitInProgress(false);
      }
    })();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="jobDescription" className="form-label">
          Erzählen Sie von sich! Was ist ihr Beruf? Arbeiten Sie von zuhause?
        </label>
        <textarea
          className="form-control"
          id="jobDescription"
          defaultValue={jobDescription}
          ref={textAreaRef}
          required
          rows={5}
          placeholder="Ich habe von zuhause und manchmal vom Büro aus als Softwareentwickler gearbeitet."
        ></textarea>
      </div>

      {submitFailed && (
        <div className="alert alert-danger mt-3" role="alert">
          Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
        </div>
      )}

      <div className="mt-3 text-end">
        {submitInProgress && (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Lädt...</span>
          </div>
        )}
        <button
          className="btn btn-primary"
          type="submit"
          disabled={submitInProgress}
        >
          Weiter
        </button>
      </div>
    </form>
  );
}
