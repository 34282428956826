import { useState, useEffect } from "react";
import { Link } from "wouter";
import { getExpenses } from "./api/expenses";
import { DocumentInfo, ExpenseInfo } from "./api/types";
import { getYearSummary, YearSummary } from "./api/year-summary";
import { DocumentLink } from "./document";
import { ExpenseRow } from "./expense-row";
import { serializeRoute, RouteKind } from "./route";

type YearSummaryProps = {
  year: number;
  summary: YearSummary;
};

function YearSummaryCard({ year, summary }: YearSummaryProps) {
  const containerStyle = {
    width: "300px",
    height: "200px",
    display: "flex",
    flexDirection: "column" as "column",
  };

  const yearUrl = serializeRoute({
    kind: RouteKind.Year,
    year: { year, step: undefined },
  });

  return (
    <div className="border shadow p-3" style={containerStyle}>
      <h3 className="text-center">{year}</h3>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <Link href={yearUrl} className="btn btn-primary">
          {(() => {
            switch (summary) {
              case YearSummary.Started:
                return "Fortsetzen";
              case YearSummary.NotStarted:
                return "Starten";
              default:
                const exhaustive: never = summary;
                throw new Error(`Unhandled: ${exhaustive}`);
            }
          })()}
        </Link>
      </div>
    </div>
  );
}

function YearSummariesSection() {
  const [yearSummaries, setYearSummaries] = useState(
    null as null | Map<number, YearSummary>,
  );
  const [fetchFailed, setFetchFailed] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setYearSummaries(await getYearSummary());
      } catch (err) {
        setFetchFailed(true);
        throw err;
      }
    })();
  }, []);

  return (
    <section>
      <h2>Steuerjahre</h2>

      {fetchFailed === true && (
        <div className="alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal!
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "stretch",
          gap: "16px",
        }}
      >
        {yearSummaries != null &&
          [...yearSummaries]
            .reverse()
            .map(([year, yearSummary]) => (
              <YearSummaryCard key={year} year={year} summary={yearSummary} />
            ))}
      </div>
    </section>
  );
}

function ExpensesSection(): JSX.Element | null {
  const [expenses, setExpenses] = useState(null as null | Array<ExpenseInfo>);
  const [fetchFailed, setFetchFailed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setExpenses((await getExpenses()).expenses);
      } catch (err) {
        setFetchFailed(true);
        throw new Error("Faled to fetch expenses summary", { cause: err });
      }
    })();
  }, []);

  const workExpenses = expenses?.filter(
    (expenseInfo) =>
      expenseInfo.workPercentage != null && expenseInfo.workPercentage > 0,
  );
  workExpenses?.sort((a, b) => {
    if (a.expense.date != null && b.expense.date != null) {
      if (a.expense.date !== b.expense.date) {
        return a.expense.date < b.expense.date ? -1 : 1;
      }
    } else {
      if (a.expense.date == null && b.expense.date != null) {
        return -1;
      }
      if (a.expense.date != null && b.expense.date == null) {
        return 1;
      }
    }

    if (a.expenseId === b.expenseId) {
      return 0;
    }

    return a.expenseId < b.expenseId ? -1 : 1;
  });

  if (workExpenses == null) {
    if (!fetchFailed) {
      return null;
    }

    return (
      <div className="alert alert-danger" role="alert">
        Ein Fehler ist aufgetreten. Bitte laden Sie die Seite noch einmal!
      </div>
    );
  }

  if (expenses == null) {
    throw new Error(
      "expenses == null but workExpenses != null should be impossible",
    );
  }

  if (workExpenses.length === 0) {
    return null;
  }

  return (
    <section>
      <h2>Arbeitsausgaben</h2>
      <table className="table">
        <tbody>
          {workExpenses.map((expenseInfo) => (
            <ExpenseRow
              key={expenseInfo.expenseId}
              expenseInfo={expenseInfo}
              year={null}
              setExpenseInfo={(newExpenseInfo) => {
                if (
                  newExpenseInfo != null &&
                  newExpenseInfo.expenseId !== expenseInfo.expenseId
                ) {
                  throw new Error("setExpenseInfo called with wrong ID");
                }

                const newExpenses =
                  newExpenseInfo == null
                    ? expenses.filter(
                        (oldExpenseInfo) =>
                          oldExpenseInfo.expenseId !== expenseInfo.expenseId,
                      )
                    : expenses.map((oldExpenseInfo) =>
                        oldExpenseInfo.expenseId === newExpenseInfo.expenseId
                          ? newExpenseInfo
                          : oldExpenseInfo,
                      );

                setExpenses(newExpenses);
              }}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}

function DocumentsSection() {
  // TODO: Show a spinner while loading, show an error if fetch failed.
  const [state, setState] = useState({
    documentInfos: [] as DocumentInfo[],
  });

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/document");
      if (response.status !== 200 /* OK */) {
        throw new Error(`Error fetching /expense: ${response}`);
      }
      const documentInfos = await response.json();
      setState((prev) => ({
        ...prev,
        documentInfos,
      }));
    })();
  }, []);

  function displaySize(byteSize: number): string {
    if (byteSize < 0) {
      throw new Error(`byte size must not be negative, got ${byteSize}`);
    }

    const kB = 1000;
    const mB = 1000 * kB;

    if (byteSize < kB) {
      return `${Math.ceil(byteSize)} B`;
    }
    if (byteSize < mB) {
      return `${Math.ceil((10 * byteSize) / kB) / 10} kB`;
    }

    return `${Math.ceil((10 * byteSize) / mB) / 10} mB`;
  }

  const documentRows: Array<JSX.Element> = state.documentInfos.map(
    (documentInfo) => {
      const detail = documentInfo.detail;
      switch (detail.documentType) {
        case "File": {
          return (
            <tr key={documentInfo.documentHash}>
              <td>
                <DocumentLink
                  documentInfo={documentInfo}
                  useContentKindLabel={false}
                />
              </td>
              <td>{displaySize(documentInfo.size)}</td>
              <td>
                {detail.lastModified != null &&
                  new Date(detail.lastModified).toLocaleDateString("de-DE")}
              </td>
            </tr>
          );
        }
        case "Mail": {
          return (
            <tr key={documentInfo.documentHash}>
              <td>
                <DocumentLink
                  documentInfo={documentInfo}
                  useContentKindLabel={false}
                />
              </td>
              <td>{displaySize(documentInfo.size)}</td>
              <td>
                {detail.date != null &&
                  new Date(detail.date).toLocaleDateString("de-DE")}
              </td>
            </tr>
          );
        }
        default:
          const exhaustive: never = detail;
          throw new Error(`Unhandled: ${exhaustive}`);
      }
    },
  );

  return (
    <section>
      <h2>Dokumente</h2>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Größe</th>
            <th scope="col">Datum</th>
          </tr>
        </thead>
        <tbody>{documentRows}</tbody>
      </table>
    </section>
  );
}

export function Home() {
  return (
    <main
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        paddingTop: "32px",
        paddingBottom: "32px",

        // TODO: Consider using this instead of the "container" class.
        // "container" adds some unnecessary horizontal margin/padding on
        // relatively small screens.
        //paddingLeft: "16px",
        //paddingRight: "16px",
        //maxWidth: "1280px",
        //margin: "auto",
      }}
    >
      <YearSummariesSection />
      <ExpensesSection />
      <DocumentsSection />
    </main>
  );
}
