import { Link } from "wouter";
import { useState, useEffect } from "react";
import { Documents } from "./year-wizard-documents";
import {
  RouteKind,
  serializeRoute,
  YearWizardStepRoute,
  YearWizardStepKind,
} from "./route";
import { YearWizardOverview } from "./year-wizard-overview";
import { ExpenseReport, ExpensesStatus, getExpensesYear } from "./api/expenses";
import { getJobDescription } from "./api/job-description";
import { Profession } from "./year-wizard-profession";
import { navigate } from "wouter/use-location";

function YearWizardContainer({
  year,
  children,
}: {
  year: number;
  children: JSX.Element | Array<JSX.Element>;
}): JSX.Element {
  return (
    <main
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        paddingTop: "32px",
        paddingBottom: "32px",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Werbungskosten {year}</h1>

      <div
        className="border rounded shadow"
        style={{
          width: "min(100%, 768px)",
          display: "flex",
          flexDirection: "column",
          // minHeight is relevant for when there's little content, e.g. a
          // spinner or an error.
          minHeight: "200px",
        }}
      >
        {children}
      </div>
    </main>
  );
}

type YearWizardProps = {
  year: number;
  selectedStep?: YearWizardStepRoute;
};

export function YearWizard({ year, selectedStep }: YearWizardProps) {
  function serializeStepRoute(step: YearWizardStepRoute): string {
    return serializeRoute({
      kind: RouteKind.Year,
      year: { year, step },
    });
  }

  const [jobDescription, setJobDescription] = useState(null as null | string);
  const [expenseReport, setExpenseReport] = useState(
    "fetching" as "fetching" | ExpenseReport,
  );
  function setExpensesStatus(status: ExpensesStatus) {
    setExpenseReport((prev) => {
      if (prev === "fetching") {
        return { expenses: [], status };
      }
      return { ...prev, status };
    });
  }

  const [initialFetchFailed, setInitialFetchFailed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Make sure not to fetch in parallel.
        setJobDescription((await getJobDescription(year)) ?? "");
        setExpenseReport(await getExpensesYear(year));
      } catch (err) {
        setInitialFetchFailed(true);
        throw new Error("Fetching year data failed", { cause: err });
      }
    })();
  }, [year]);

  // TODO: The timer we set here will not fire if expenseReport changes at
  // least once every 15 seconds, but there's no reason why it shouldn't.
  useEffect(() => {
    if (
      expenseReport === "fetching" ||
      expenseReport.status !== ExpensesStatus.Processing
    ) {
      return () => {};
    }

    const timeoutId = setTimeout(async () => {
      setExpenseReport(await getExpensesYear(year));
    }, 15 * 1000);

    return () => clearTimeout(timeoutId);
  }, [expenseReport, year]);

  if (jobDescription == null || expenseReport === "fetching") {
    return (
      <YearWizardContainer year={year}>
        {initialFetchFailed ? (
          <div className="alert alert-danger" role="alert">
            Beim Laden Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen
            Sie es später noch einmal!
          </div>
        ) : (
          <div
            // Vertically and horizontally centered in the content area.
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Lädt...</span>
            </div>
          </div>
        )}
      </YearWizardContainer>
    );
  }

  const defaultStep: YearWizardStepKind =
    jobDescription === ""
      ? YearWizardStepKind.Profession
      : YearWizardStepKind.Overview;

  if (selectedStep == null) {
    // To make navigation back/forward in history work as expected, we need to
    // use { replace: true } here. Otherwise, navigating back would get the user
    // to /year/${year}, which would then send him to e.g.
    // /year/${year}/profession again.
    setTimeout(() => {
      navigate(serializeStepRoute({ kind: defaultStep }), {
        replace: true,
      });
    }, 0);
  }

  function onProfessionDone() {
    navigate(serializeStepRoute({ kind: YearWizardStepKind.Documents }));
  }

  function onDocumentsDone() {
    navigate(serializeStepRoute({ kind: YearWizardStepKind.Overview }));
  }

  function stepProgressItemClass(stepItem: YearWizardStepKind): string {
    if (stepItem === selectedStep?.kind) {
      return "active";
    }

    return "";
  }

  return (
    <YearWizardContainer year={year}>
      <div
        className="list-group list-group-item-action list-group-horizontal"
        style={{ width: "100%" }}
      >
        <Link
          href={serializeStepRoute({ kind: YearWizardStepKind.Profession })}
          className={`list-group-item flex-fill ${stepProgressItemClass(
            YearWizardStepKind.Profession,
          )}`}
        >
          1. Beruf
        </Link>
        <Link
          href={serializeStepRoute({ kind: YearWizardStepKind.Documents })}
          className={`list-group-item flex-fill ${stepProgressItemClass(
            YearWizardStepKind.Documents,
          )}`}
        >
          2. Dokumente
        </Link>
        <Link
          href={serializeStepRoute({ kind: YearWizardStepKind.Overview })}
          className={`list-group-item flex-fill ${stepProgressItemClass(
            YearWizardStepKind.Overview,
          )}`}
        >
          3. Übersicht
        </Link>
      </div>

      <div style={{ margin: "16px" }}>
        {/* We want to render only a single child here. We accomplish this by hiding all but one element.
            This way, those elements maintain their state when the user navigates back and forth. It's also
            nice for state that is fetched in those components, because by keeping the elements around we avoid
            fetching twice (=> less lag, less bandwith). This comes at the cost of a bit more memory & CPU, but
            I haven't noticed any performance issues so far.
        */}
        <span hidden={selectedStep != null}>TODO: Spinner</span>

        <div hidden={selectedStep?.kind !== YearWizardStepKind.Profession}>
          <Profession
            year={year}
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
            onDone={onProfessionDone}
            setExpensesStatus={setExpensesStatus}
          />
        </div>
        <div hidden={selectedStep?.kind !== YearWizardStepKind.Documents}>
          <Documents
            year={year}
            oauthError={
              selectedStep?.kind === YearWizardStepKind.Documents
                ? selectedStep.oauthError
                : undefined
            }
            onDone={onDocumentsDone}
            setExpensesStatus={setExpensesStatus}
          />
        </div>
        <div hidden={selectedStep?.kind !== YearWizardStepKind.Overview}>
          <YearWizardOverview
            year={year}
            expenseReport={expenseReport}
            setExpenseReport={setExpenseReport}
          />
        </div>
      </div>
    </YearWizardContainer>
  );
}
