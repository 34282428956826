import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Landing from "./landing";
import "bootstrap/dist/css/bootstrap.css";
import { useCookies } from "react-cookie";
import Navbar from "./navbar";
import { Impressum } from "./impressum";
import { Datenschutz } from "./datenschutz";
import { Agb } from "./agb";
import { useLocation } from "wouter";
import { Signin, SigninConfirm } from "./signin";
import { Signup, SignupConfirm } from "./signup";
import Footer from "./footer";
import { Home } from "./home";
import { YearWizard } from "./year-wizard";
import { Route, RouteKind, parseRoute, serializeRoute } from "./route";

// Check session on load. If the session is not valid, the response will unset session cookies.
(async function () {
  try {
    await fetch("/api/session", {
      method: "POST",
    });
  } catch (err) {}
})();

function isSignedOutOnlyRoute(route: Route): boolean {
  switch (route.kind) {
    case RouteKind.Home:
      return false;
    case RouteKind.Year:
      return false;
    case RouteKind.Impressum:
      return false;
    case RouteKind.Datenschutz:
      return false;
    case RouteKind.Agb:
      return false;
    case RouteKind.Signup:
      return true;
    case RouteKind.SignupConfirm:
      return true;
    case RouteKind.Signin:
      return true;
    case RouteKind.SigninConfirm:
      return true;
    default: {
      const exhaustive: never = route;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}

function isSignedInOnlyRoute(route: Route): boolean {
  switch (route.kind) {
    case RouteKind.Home:
      return false;
    case RouteKind.Year:
      return true;
    case RouteKind.Impressum:
      return false;
    case RouteKind.Datenschutz:
      return false;
    case RouteKind.Agb:
      return false;
    case RouteKind.Signup:
      return false;
    case RouteKind.SignupConfirm:
      return false;
    case RouteKind.Signin:
      return false;
    case RouteKind.SigninConfirm:
      return false;
    default: {
      const exhaustive: never = route;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}

function Root() {
  const cookies = useCookies(["user"])[0];
  const [location, setLocation] = useLocation();

  const [showSigninUnknownError, setShowSigninUnknownError] = useState(false);

  function onSigninPending() {
    setLocation("/signin/confirm");
    setLocation(serializeRoute({ kind: RouteKind.SigninConfirm }));
    setShowSigninUnknownError(false);
  }
  function onSignupPending() {
    setLocation(serializeRoute({ kind: RouteKind.SignupConfirm }));
    setShowSigninUnknownError(false);
  }
  function onSigninUnknown() {
    setShowSigninUnknownError(true);
    setLocation(serializeRoute({ kind: RouteKind.Signup }));
  }

  const signedIn = cookies.user != null;
  const route: Route | null = (() => {
    try {
      return parseRoute(location);
    } catch (err) {
      // TODO: 404 page?
      return null;
    }
  })();

  const shouldRedirectHome =
    route == null ||
    (signedIn && isSignedOutOnlyRoute(route)) ||
    (!signedIn && isSignedInOnlyRoute(route));

  if (shouldRedirectHome) {
    setLocation(serializeRoute({ kind: RouteKind.Home }));
  }

  const routeElement = (() => {
    switch (route?.kind) {
      case undefined:
        return null;
      case RouteKind.Home:
        return signedIn ? <Home /> : <Landing />;
      case RouteKind.Year:
        return (
          <YearWizard year={route.year.year} selectedStep={route.year.step} />
        );
      case RouteKind.Impressum:
        return <Impressum />;
      case RouteKind.Datenschutz:
        return <Datenschutz />;
      case RouteKind.Agb:
        return <Agb />;
      case RouteKind.Signup:
        return (
          <Signup
            onSignupPending={onSignupPending}
            onSigninPending={onSigninPending}
            showSigninUnknownError={showSigninUnknownError}
          />
        );
      case RouteKind.SignupConfirm:
        return <SignupConfirm />;
      case RouteKind.Signin:
        return (
          <Signin
            onSigninPending={onSigninPending}
            onSigninUnknown={onSigninUnknown}
          />
        );
      case RouteKind.SigninConfirm:
        return <SigninConfirm />;
      default: {
        const exhaustive: never = route;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  return (
    <>
      <Navbar />

      {routeElement}

      <Footer />
    </>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
      })
      .catch((error) => {
        console.log("ServiceWorker registration failed: ", error);
      });
  });
}
