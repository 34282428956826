export type FileInfoDetail = {
  documentType: "File";
  filePath: string;
  lastModified: string;
};

export type MailInfoDetail = {
  documentType: "Mail";
  subject?: string;
  date?: string;
};

export enum DocumentKind {
  OrderConfirmation = "OrderConfirmation",
  Invoice = "Invoice",
  Receipt = "Receipt",
  ShippingConfirmation = "ShippingConfirmation",
  Other = "Other",
}

export type DocumentInfoDetail = FileInfoDetail | MailInfoDetail;

export type DocumentInfo = {
  documentHash: string;
  size: number;
  detail: DocumentInfoDetail;
  contentKind?: DocumentKind;
};

export type MoneyAmount = string;

export enum Currency {
  USD = "USD",
  EUR = "EUR",
  JPY = "JPY",
  GBP = "GBP",
  CNY = "CNY",
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  HKD = "HKD",
  SGD = "SGD",
  SEK = "SEK",
  KRW = "KRW",
  NOK = "NOK",
  NZD = "NZD",
  INR = "INR",
  MXN = "MXN",
  TWD = "TWD",
  ZAR = "ZAR",
  BRL = "BRL",
  DKK = "DKK",
  PLN = "PLN",
  THB = "THB",
  ILS = "ILS",
  IDR = "IDR",
  CZK = "CZK",
  AED = "AED",
  TRY = "TRY",
  HUF = "HUF",
  CLP = "CLP",
  SAR = "SAR",
  PHP = "PHP",
  MYR = "MYR",
  COP = "COP",
  RUB = "RUB",
  RON = "RON",
  PEN = "PEN",
  BHD = "BHD",
  BGN = "BGN",
  ARS = "ARS",
}

export function shortCurrencyString(currency: Currency): string {
  switch (currency) {
    case Currency.USD:
      return "$";
    case Currency.EUR:
      return "€";
    case Currency.JPY:
      return "¥";
    case Currency.GBP:
      return "£";
    case Currency.CNY:
      return "CN¥";
    case Currency.AUD:
      return "AU$";
    case Currency.CAD:
      return "CA$";
    case Currency.HKD:
      return "HK$";
    case Currency.KRW:
      return "₩";
    case Currency.NZD:
      return "NZ$";
    case Currency.INR:
      return "₹";
    case Currency.MXN:
      return "MX$";
    case Currency.TWD:
      return "NT$";
    case Currency.BRL:
      return "R$";
    case Currency.THB:
      return "฿";
    case Currency.ILS:
      return "₪";

    case Currency.AED:
    case Currency.TRY:
    case Currency.HUF:
    case Currency.CLP:
    case Currency.SAR:
    case Currency.PHP:
    case Currency.MYR:
    case Currency.COP:
    case Currency.RUB:
    case Currency.RON:
    case Currency.PEN:
    case Currency.BHD:
    case Currency.BGN:
    case Currency.ARS:
    case Currency.CZK:
    case Currency.IDR:
    case Currency.PLN:
    case Currency.DKK:
    case Currency.CHF:
    case Currency.SGD:
    case Currency.SEK:
    case Currency.NOK:
    case Currency.ZAR:
      return currency;
    default:
      const exhaustive: never = currency;
      throw new Error(`Unhandled: ${exhaustive}`);
  }
}

export const CURRENCIES: Array<Currency> = [
  Currency.USD,
  Currency.EUR,
  Currency.JPY,
  Currency.GBP,
  Currency.CNY,
  Currency.AUD,
  Currency.CAD,
  Currency.CHF,
  Currency.HKD,
  Currency.SGD,
  Currency.SEK,
  Currency.KRW,
  Currency.NOK,
  Currency.NZD,
  Currency.INR,
  Currency.MXN,
  Currency.TWD,
  Currency.ZAR,
  Currency.BRL,
  Currency.DKK,
  Currency.PLN,
  Currency.THB,
  Currency.ILS,
  Currency.IDR,
  Currency.CZK,
  Currency.AED,
  Currency.TRY,
  Currency.HUF,
  Currency.CLP,
  Currency.SAR,
  Currency.PHP,
  Currency.MYR,
  Currency.COP,
  Currency.RUB,
  Currency.RON,
  Currency.PEN,
  Currency.BHD,
  Currency.BGN,
  Currency.ARS,
];

export type Expense = {
  name: string;
  vendor?: string;
  date?: string;
  amount: MoneyAmount;
  currency?: Currency;
};

export type ExpenseDeductibility = {
  rating: number;
  reasoning: String;
};

export enum DataSource {
  Generated = "Generated",
  User = "User",
}

export type ExpenseInfo = {
  expenseId: number;
  expense: Expense;
  receipts: Array<DocumentInfo>;
  deductibility?: ExpenseDeductibility;
  workPercentage?: number;
  dataSource: DataSource;
};
