export type MailImportMethod =
  | { type: "available" }
  | { type: "availableOAuth"; emailProviderName: string; authUrl: string }
  | { type: "availableGmail" }
  | { type: "unsupportedByUs"; emailProviderName?: string }
  | { type: "unsupportedByThem"; emailProviderName: string }
  | { type: "insecure"; emailProviderName: string };

export enum MailImportStatusKind {
  NotStarted = "notStarted",
  InProgress = "inProgress",
  Completed = "completed",
  Failed = "failed",
}

export type MailImportStatus =
  | { kind: MailImportStatusKind.NotStarted }
  | { kind: MailImportStatusKind.InProgress }
  | { kind: MailImportStatusKind.Completed; lastStartDate: string }
  | { kind: MailImportStatusKind.Failed };

export type MailImportInfo = {
  availableMethod: MailImportMethod;
  status: MailImportStatus;
};

export async function getMailImportInfo(year: number): Promise<MailImportInfo> {
  const url = `/api/mail-import/${year}`;
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }

    return await response.json();
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}

export type MailImportRequest = {
  imapSecret: string;
};

export enum PostMailImportResult {
  Ok = "Ok",
  ErrorCredentials = "ErrorCredentials",
}

export async function postMailImport(
  year: number,
  documentImport: MailImportRequest,
): Promise<PostMailImportResult> {
  const response = await fetch(`/api/mail-import/${year}`, {
    method: "POST",
    body: JSON.stringify(documentImport),
    headers: { "Content-Type": "application/json" },
  });

  if (response.status === 403 /* FORBIDDEN */) {
    return PostMailImportResult.ErrorCredentials;
  }

  if (response.status !== 200 /* OK */) {
    throw new Error(`Failed to post document import: ${response}`);
  }

  return PostMailImportResult.Ok;
}
