function parseIntFull(s: string): number | null {
  if (!/^\d+$/.test(s)) {
    return null;
  }

  return parseInt(s, 10);
}

export enum YearWizardStepKind {
  Profession = "profession",
  Documents = "documents",
  Overview = "overview",
}

export type YearWizardStepRoute =
  | { kind: YearWizardStepKind.Profession }
  | { kind: YearWizardStepKind.Documents; oauthError?: {} }
  | { kind: YearWizardStepKind.Overview; expenseId?: number };

export type YearWizardRoute = { year: number; step?: YearWizardStepRoute };

function parseYearDocumentRoute(segments: Array<string>): YearWizardStepRoute {
  const first = segments.shift();
  switch (first) {
    case undefined:
      return { kind: YearWizardStepKind.Documents };
    case "oauth-error": {
      return { kind: YearWizardStepKind.Documents, oauthError: {} };
    }
    default: {
      throw new Error(`Invalid year document route: ${first}`);
    }
  }
}

function parseYearRoute(segments: Array<string>): YearWizardRoute {
  const first = segments.shift();
  if (first == null) {
    throw new Error(`Missing year`);
  }
  const year = parseIntFull(first);
  if (year == null) {
    throw new Error(`Invalid year: "${first}"`);
  }

  const second = segments.shift();
  const step: YearWizardStepRoute | undefined = (() => {
    switch (second) {
      case undefined:
        return undefined;
      case "profession":
        return { kind: YearWizardStepKind.Profession };
      case "documents":
        return parseYearDocumentRoute(segments);
      case "overview": {
        const third = segments.shift();
        if (third == null) {
          return { kind: YearWizardStepKind.Overview };
        }

        const expenseId = parseIntFull(third);
        if (expenseId == null) {
          throw new Error(`Invalid expense ID: ${third}`);
        }
        return { kind: YearWizardStepKind.Overview, expenseId };
      }
      default:
        throw new Error(`Invalid year step: ${second}`);
    }
  })();

  return { year, step };
}

function serializeYearWizardRoute(yearRoute: YearWizardRoute): string {
  let result = `/${yearRoute.year}`;

  const step = yearRoute.step;
  switch (step?.kind) {
    case undefined:
      return result;
    case YearWizardStepKind.Profession:
      result += "/profession";
      break;
    case YearWizardStepKind.Documents:
      result += "/documents";
      if (step.oauthError != null) {
        result += `/oauth-error`;
      }
      break;
    case YearWizardStepKind.Overview:
      result += "/overview";
      if (step.expenseId != null) {
        result += `/${step.expenseId}`;
      }
      break;
    default: {
      const exhaustive: never = step;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }

  return result;
}

export enum RouteKind {
  Home = "home",
  Year = "year",
  Signup = "signup",
  SignupConfirm = "signupConfirm",
  Signin = "signin",
  SigninConfirm = "signinConfirm",
  Impressum = "impressum",
  Datenschutz = "datenschutz",
  Agb = "agb",
}

export type Route =
  | { kind: RouteKind.Home }
  | { kind: RouteKind.Year; year: YearWizardRoute }
  | { kind: RouteKind.Signup }
  | { kind: RouteKind.SignupConfirm }
  | { kind: RouteKind.Signin }
  | { kind: RouteKind.SigninConfirm }
  | { kind: RouteKind.Impressum }
  | { kind: RouteKind.Datenschutz }
  | { kind: RouteKind.Agb };

function parseRouteImpl(location: string): Route {
  const segments = location.split("/");
  // Every location string begins with "/", so the first
  // part must be empty.
  const emptyFirst = segments.shift();
  if (emptyFirst !== "") {
    throw new Error(`Location does not start with /: ${location}`);
  }

  const route: Route = (() => {
    const head = segments.shift();
    switch (head) {
      case undefined:
      case "":
        return { kind: RouteKind.Home };
      case "year":
        return { kind: RouteKind.Year, year: parseYearRoute(segments) };
      case "signup": {
        const second = segments.shift();
        switch (second) {
          case undefined:
            return { kind: RouteKind.Signup };
          case "confirm":
            return { kind: RouteKind.SignupConfirm };
          default:
            throw new Error(`Invalid signup route ${second}`);
        }
      }
      case "signin": {
        const second = segments.shift();
        switch (second) {
          case undefined:
            return { kind: RouteKind.Signin };
          case "confirm":
            return { kind: RouteKind.SigninConfirm };
          default:
            throw new Error(`Invalid signin route ${second}`);
        }
      }
      case "impressum":
        return { kind: RouteKind.Impressum };
      case "datenschutz":
        return { kind: RouteKind.Datenschutz };
      case "agb":
        return { kind: RouteKind.Agb };
      default:
        throw new Error(`Invalid top level route: ${head}`);
    }
  })();

  if (segments.length !== 0) {
    throw new Error(
      `Trailing segments for location "${location}" after parsed route ${route}: ${segments}`,
    );
  }

  return route;
}

function serializeRouteImpl(route: Route): string {
  switch (route.kind) {
    case RouteKind.Home:
      return "/";
    case RouteKind.Year:
      return "/year" + serializeYearWizardRoute(route.year);
    case RouteKind.Signup:
      return "/signup";
    case RouteKind.SignupConfirm:
      return "/signup/confirm";
    case RouteKind.Signin:
      return "/signin";
    case RouteKind.SigninConfirm:
      return "/signin/confirm";
    case RouteKind.Impressum:
      return "/impressum";
    case RouteKind.Datenschutz:
      return "/datenschutz";
    case RouteKind.Agb:
      return "/agb";
    default: {
      const exhaustive: never = route;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}

export function parseRoute(location: string): Route {
  const parsed = parseRouteImpl(location);

  // TODO: Do not do this in production.
  const serializedParsed = serializeRouteImpl(parsed);
  if (location !== serializedParsed) {
    throw new Error(
      `serializeRoute(parseRoute(location)) != location: ${location} !== ${serializedParsed}`,
    );
  }

  return parsed;
}

export function serializeRoute(route: Route): string {
  const serialized = serializeRouteImpl(route);

  // TODO: Do not do this in production.
  const parsedSerialized: Route = (() => {
    try {
      return parseRouteImpl(serialized);
    } catch (err) {
      throw new Error("parseRoute(serializeRoute(route)) failed", {
        cause: err,
      });
    }
  })();

  if (JSON.stringify(parsedSerialized) !== JSON.stringify(route)) {
    throw new Error(
      `parseRoute(serializeRoute(route)) !== route: ${JSON.stringify(
        parsedSerialized,
      )} !== ${JSON.stringify(route)}`,
    );
  }

  return serialized;
}
