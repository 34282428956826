import { useState, MouseEvent } from "react";
import { DocumentUpload } from "./document";
import { ImapForm } from "./imap";
import { ExpensesStatus } from "./api/expenses";
import { RouteKind, serializeRoute, YearWizardStepKind } from "./route";

export type YearDocumentsProps = {
  year: number;
  oauthError: {} | undefined;
  setExpensesStatus: (expensesStatus: ExpensesStatus) => void;
  onDone: () => void;
};

export function Documents({
  year,
  oauthError,
  onDone,
  setExpensesStatus,
}: YearDocumentsProps) {
  const [imapFormExpanded, setImapFormExpanded] = useState(true);
  const [forwardingFormExpanded, setForwardingFormExpanded] = useState(false);
  const [fileFormExpanded, setFileFormExpanded] = useState(false);

  function onImapHeadingClick(ev: MouseEvent): void {
    ev.preventDefault();
    setImapFormExpanded(!imapFormExpanded);
  }
  function onForwardingHeadingClick(ev: MouseEvent): void {
    ev.preventDefault();
    setForwardingFormExpanded(!forwardingFormExpanded);
  }
  function onFileHeadingClick(ev: MouseEvent): void {
    ev.preventDefault();
    setFileFormExpanded(!fileFormExpanded);
  }

  function expansionTriangle(isExpanded: boolean): string {
    return isExpanded ? "⌃" : "⌄";
  }

  async function onProceedButtonClick(_ev: MouseEvent): Promise<void> {
    try {
      setExpensesStatus(ExpensesStatus.Processing);
      onDone();
    } catch (err) {
      throw new Error(`Failed to submit document import`, { cause: err });
    }
  }

  return (
    <>
      <p>
        Hier können Sie Dokumente hochladen, die Werbungskostenrechner.de nach
        Belegen für die Steuererklärung durchsucht.
      </p>
      <p>Wählen Sie aus den folgenden Alternativen:</p>

      <ul className="list-group">
        <li className="list-group-item">
          <h5>
            <button
              type="button"
              className="btn btn-link"
              onClick={onImapHeadingClick}
              style={{ textDecoration: "none", font: "inherit", padding: 0 }}
            >
              {expansionTriangle(imapFormExpanded)} Email-Postfach einbinden
            </button>
          </h5>
          <div hidden={!imapFormExpanded} className="mt-3">
            <p>
              Werbungskostenrechner.de ruft Dokumente direkt aus Ihrem
              Email-Postfach ab.
            </p>
            <ImapForm
              oauthRedirectURL={serializeRoute({
                kind: RouteKind.Year,
                year: { year, step: { kind: YearWizardStepKind.Documents } },
              })}
              year={year}
            />
            {oauthError != null && (
              <div className="alert alert-danger mt-3" role="alert">
                Ein Fehler ist aufgetreten. Versuchen Sie es noch einmal, oder
                benutzen Sie eine der anderen Optionen um Dokumente hochzuladen.
              </div>
            )}
          </div>
        </li>
        <li className="list-group-item">
          <h5>
            <button
              type="button"
              className="btn btn-link"
              onClick={onForwardingHeadingClick}
              style={{ textDecoration: "none", font: "inherit", padding: 0 }}
            >
              {expansionTriangle(forwardingFormExpanded)} Emails weiterleiten
            </button>
          </h5>
          <div hidden={!forwardingFormExpanded} className="mt-3">
            <p>
              Leiten Sie ausgewählte Emails und andere Dokumente direkt weiter
              an die Werbungskostenrechner.de Emailadresse:
            </p>
            <p className="text-center">
              <a href="mailto:dokumente@werbungskostenrechner.de">
                dokumente@werbungskostenrechner.de
              </a>
            </p>
            <p>
              Alle Emails, die Sie an diese Adresse schicken, werden automatisch
              nach Belegen für Ihre Steuererklärung {year} untersucht.
            </p>
            {/* TODO: Anleitung schreiben */}
          </div>
        </li>
        <li className="list-group-item">
          <h5>
            <button
              type="button"
              className="btn btn-link"
              onClick={onFileHeadingClick}
              style={{ textDecoration: "none", font: "inherit", padding: 0 }}
            >
              {expansionTriangle(fileFormExpanded)} Dateien hochladen
            </button>
          </h5>
          <div hidden={!fileFormExpanded} className="mt-3">
            <p>Laden Sie Dokumente direkt von Ihrem Computer hoch.</p>
            <DocumentUpload
              onNewUploads={(_infos) => {
                setExpensesStatus(ExpensesStatus.Processing);
              }}
            />
            <p>
              Werbungskostenrechner.de berücksichtigt auch Dokumente, die Sie
              schon für andere Jahre hochgeladen haben.
            </p>
          </div>
        </li>
      </ul>
      <div className="mt-3 text-end">
        <button onClick={onProceedButtonClick} className="btn btn-primary">
          Weiter
        </button>
      </div>
    </>
  );
}
