import { ExpenseReport, ExpensesStatus } from "./api/expenses";
import { ExpenseInfo, MoneyAmount, Currency } from "./api/types";
import { ExpenseRow, deductAmount } from "./expense-row";
import { formatPrice } from "./format-price";

function totalDeductAmounts(
  expenseInfos: Array<ExpenseInfo>,
): Map<Currency, MoneyAmount> {
  const deductAmounts = new Map<Currency, MoneyAmount>();
  for (const expenseInfo of expenseInfos) {
    const { expense, workPercentage } = expenseInfo;
    const { currency } = expense;
    if (currency == null || workPercentage == null || workPercentage === 0) {
      continue;
    } else {
      const deduct = deductAmount(expense.amount, workPercentage);
      const current = deductAmounts.get(currency);

      if (current != null) {
        deductAmounts.set(
          currency,
          (Number.parseFloat(current) + Number.parseFloat(deduct)).toFixed(2),
        );
      } else {
        deductAmounts.set(currency, deduct);
      }
    }
  }

  return deductAmounts;
}

export type YearWizardOverviewProps = {
  expenseReport: ExpenseReport;
  setExpenseReport: (newValue: ExpenseReport) => void;
  year: number;
};

export function YearWizardOverview({
  expenseReport,
  setExpenseReport,
  year,
}: YearWizardOverviewProps): JSX.Element {
  let { expenses, status } = expenseReport;

  const processingDisclaimer = (() => {
    switch (status) {
      case ExpensesStatus.Processing:
        return (
          <div className="alert alert-warning" role="alert">
            Werbungskostenrechner.de analysiert Ihre Ausgaben. Schauen Sie in
            einigen Minuten nochmal vorbei!
          </div>
        );
      case ExpensesStatus.Complete:
        return null;
      default: {
        const exhaustive: never = status;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  const deductibleExpenses = [];
  const nonDeductibleExpenses = [];
  for (const expenseInfo of expenses) {
    if (
      expenseInfo.workPercentage == null ||
      expenseInfo.workPercentage === 0
    ) {
      nonDeductibleExpenses.push(expenseInfo);
    } else {
      deductibleExpenses.push(expenseInfo);
    }
  }

  // Try to maintain a stable sort order among deductible expenses by sorting by expenseId.
  deductibleExpenses.sort((a, b) => {
    if (a.expenseId === b.expenseId) {
      return 0;
    }

    return a.expenseId < b.expenseId ? 1 : -1;
  });

  // We sort non deductible expenses as follows:
  // - Deductibility rating: Highest first, since we want to show the expenses that were
  //   misclassified as private first.
  // - ExpenseId: To maintain a stable sort order otherwise.
  nonDeductibleExpenses.sort((a, b) => {
    if (a.deductibility != null && b.deductibility != null) {
      if (a.deductibility.rating !== b.deductibility.rating) {
        return a.deductibility.rating > b.deductibility.rating ? 1 : -1;
      }
    } else {
      if (a.deductibility == null && b.deductibility != null) {
        return 1;
      }
      if (a.deductibility != null && b.deductibility == null) {
        return -1;
      }
    }

    if (a.expenseId === b.expenseId) {
      return 0;
    }

    return a.expenseId < b.expenseId ? 1 : -1;
  });

  const totalDeduct = Array.from(totalDeductAmounts(expenses));

  return (
    <>
      {processingDisclaimer}
      {expenses.length === 0 ? (
        <p>
          Keine Rechnungen gefunden! Binden Sie Ihre Dokumente ein, um Ihre
          Werbungskosten zu berechnen.
        </p>
      ) : (
        <div className="alert alert-warning" role="alert">
          <strong>Wichtig:</strong> Bitte überprüfen Sie Geldbeträge und andere
          Daten in den jeweiligen Belegen und korrigieren Sie diese
          gegebenenfalls. Einschätzungen zur Absetzbarkeit sind unverbindlich
          und müssen von Ihnen geprüft werden.
        </div>
      )}

      {deductibleExpenses.length > 0 && (
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <h2>Absetzbare Ausgaben</h2>
          </div>

          <table className="table">
            <tbody>
              {totalDeduct.length >= 1 && (
                <tr style={{ fontWeight: "bold" }}>
                  <td colSpan={2}>Gesamt</td>
                  <td>
                    {totalDeduct.map(([currency, amount]) => (
                      <div key={currency}>
                        <strong>{formatPrice(amount, currency)}</strong>
                      </div>
                    ))}
                  </td>
                </tr>
              )}
              {deductibleExpenses.map((expenseInfo) => (
                <ExpenseRow
                  year={year}
                  key={expenseInfo.expenseId}
                  expenseInfo={expenseInfo}
                  setExpenseInfo={(newExpenseInfo) => {
                    if (
                      newExpenseInfo != null &&
                      newExpenseInfo.expenseId !== expenseInfo.expenseId
                    ) {
                      throw new Error("setExpenseInfo called with wrong ID");
                    }

                    const newExpenses =
                      newExpenseInfo == null
                        ? expenses.filter(
                            (oldExpenseInfo) =>
                              oldExpenseInfo.expenseId !==
                              expenseInfo.expenseId,
                          )
                        : expenses.map((oldExpenseInfo) =>
                            oldExpenseInfo.expenseId ===
                            newExpenseInfo.expenseId
                              ? newExpenseInfo
                              : oldExpenseInfo,
                          );

                    setExpenseReport({
                      ...expenseReport,
                      expenses: newExpenses,
                    });
                  }}
                />
              ))}
            </tbody>
          </table>
        </>
      )}

      {nonDeductibleExpenses.length > 0 && (
        <>
          <h2>Private Ausgaben</h2>
          <table className="table">
            <tbody>
              {nonDeductibleExpenses.map((expenseInfo) => (
                <ExpenseRow
                  key={expenseInfo.expenseId}
                  year={year}
                  expenseInfo={expenseInfo}
                  setExpenseInfo={(newExpenseInfo) => {
                    if (
                      newExpenseInfo != null &&
                      newExpenseInfo.expenseId !== expenseInfo.expenseId
                    ) {
                      throw new Error("setExpenseInfo called with wrong ID");
                    }

                    const newExpenses =
                      newExpenseInfo == null
                        ? expenses.filter(
                            (oldExpenseInfo) =>
                              oldExpenseInfo.expenseId !==
                              expenseInfo.expenseId,
                          )
                        : expenses.map((oldExpenseInfo) =>
                            oldExpenseInfo.expenseId ===
                            newExpenseInfo.expenseId
                              ? newExpenseInfo
                              : oldExpenseInfo,
                          );

                    setExpenseReport({
                      ...expenseReport,
                      expenses: newExpenses,
                    });
                  }}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
