import { ExpenseInfo } from "./types";

export enum ExpensesStatus {
  Complete = "complete",
  Processing = "processing",
}

export type ExpenseReport = {
  expenses: Array<ExpenseInfo>;
  status: ExpensesStatus;
};

export async function getExpensesYear(year: number): Promise<ExpenseReport> {
  const url = `/api/expenses/${year}`;
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }

    return await response.json();
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}

export async function getExpenses(): Promise<ExpenseReport> {
  const url = `/api/expenses`;
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }

    return await response.json();
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}
