export enum YearSummary {
  NotStarted = "NotStarted",
  Started = "Started",
}

export type YearSummaries = Map<number, YearSummary>;

export async function getYearSummary(): Promise<YearSummaries> {
  const url = `/api/year-summary`;
  try {
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error(`Unexpected status: ${response.status}`);
    }

    const json = await response.json();
    const entries = Object.entries(json);
    return new Map(
      entries.map(([key, val]: [any, any]) => [
        parseInt(key),
        val as YearSummary,
      ]),
    );
  } catch (err) {
    throw new Error(`$Fetch from ${url} failed`, { cause: err });
  }
}
