import { MoneyAmountInput, parseMoneyAmount } from "./money-value-input";
import { Popover, Overlay } from "react-bootstrap";
import { DocumentLink } from "./document";
import { deleteExpense, updateExpense } from "./api/expense";
import {
  ExpenseInfo,
  MoneyAmount,
  CURRENCIES,
  Currency,
  shortCurrencyString,
} from "./api/types";
import { createRef, useState } from "react";
import { formatPrice } from "./format-price";

const NAME_COL_STYLE: React.CSSProperties = {
  width: "100%",
  wordBreak: "break-all",
};
const DEDUCT_COL_STYLE = { minWidth: "160px" };
const EXPAND_COL_STYLE = { width: "10px" };

type ExpenseRowProps = {
  expenseInfo: ExpenseInfo;
  setExpenseInfo: (expenseInfo: ExpenseInfo | undefined) => void;
  year: number | null;
};

function blurOnEnter<T extends HTMLElement>(ev: React.KeyboardEvent<T>) {
  if (ev.key === "Enter") {
    if (!(ev.target instanceof HTMLElement)) {
      throw new Error("This handler must only be installed on HTMLElements");
    }
    ev.target.blur();
  }
}

export function deductAmount(
  amount: MoneyAmount,
  workPercentage: number,
): MoneyAmount {
  // TODO: Use fractional math -- this uses floating point, which is not suitable for financial
  // data.
  const newNumberValue: number =
    Number.parseFloat(amount) * (workPercentage / 100);
  // Format newNumberValue with 2 decimal places.
  return newNumberValue.toFixed(2);
}

export function ExpenseRow({
  expenseInfo,
  setExpenseInfo,
  year,
}: ExpenseRowProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const { expense, receipts, workPercentage } = expenseInfo;
  const { name, amount, currency, date, vendor } = expense;

  const cellBorderStyle: React.CSSProperties = {
    borderBottom: isExpanded ? "0" : undefined,
    backgroundColor: isExpanded ? "#f8f9fa" : undefined,
  };
  const detailsCellStyle: React.CSSProperties = {
    backgroundColor: "#f8f9fa", // Light-grey
  };
  const expandedRowStyle: React.CSSProperties = isExpanded
    ? {
        borderBottom: "solid",
        borderBottomWidth: "0.6666666px",
      }
    : {};

  const [isEditingName, setIsEditingName] = useState(false);

  const workPercentageControl = createRef<HTMLInputElement>();
  const dateControl = createRef<HTMLInputElement>();

  const [showWorkPercentagePopover, setShowWorkPercentagePopover] =
    useState(false);
  const [showDatePopover, setShowDatePopover] = useState(false);

  function toggleIsExpanded() {
    const nextExpanded = !isExpanded;
    setIsExpanded(nextExpanded);
    if (!nextExpanded) {
      setIsEditingName(false);
    }
  }

  function handleNameBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newName = ev.target.value;
    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        name: newName,
      },
    });
    setIsEditingName(false);

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          name: newName,
        });
      } catch (err) {
        console.error("Failed to post expense name update");
        // We changed local state to the new value eagerly because the server will very likely
        // accept the change. If something goes wrong though, we should try to get the local state
        // back in sync with the server.
        //
        // TODO:
        // - Race conditions -- what if there are multiple edits in flight, with an error in a
        //   previous edit resulting in roll back of a successful later edit?
        // - Display an error message to the user instead of just silently reverting to the old
        //   value.
        //
        // Same TODOs for other fields.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleDateBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newDate = new Date(ev.target.value);
    // Format date in "yyyy-MM-dd" format.
    const newDateString = newDate.toISOString().split("T")[0];
    const newYear = new Date(newDate).getFullYear();

    // TODO: Check that date is actually a valid date.
    if (year != null && year !== newYear) {
      setShowDatePopover(true);
      return;
    }

    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        date: newDateString,
      },
    });

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          date: newDateString,
        });
      } catch (err) {
        console.error(`Failed to update expense date`);
        // Reset to the previous value.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleVendorBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newVendor = ev.target.value;
    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        vendor: newVendor,
      },
    });

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          vendor: newVendor,
        });
      } catch (err) {
        // Reset to the previous value.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleAmountBlur(ev: React.FocusEvent<HTMLInputElement>) {
    const newAmount = parseMoneyAmount(ev.target.value);
    console.log(newAmount);
    if (newAmount == null) {
      // This should be impossible by the contract of MoneyAmountInput; perhaps we should encode
      // it in the type system.
      throw new Error(
        `Invalid amount does not parse to MoneyAmount: ${newAmount}`,
      );
    }

    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        amount: newAmount,
      },
    });

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          amount: newAmount,
        });
      } catch (err) {
        console.error(`Failed to update expense amount`);
        // Reset to the previous value.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleCurrencyBlur(ev: React.FocusEvent<HTMLSelectElement>) {
    const newCurrency: Currency | undefined = CURRENCIES.find(
      (cur) => cur === ev.target.value,
    );
    if (newCurrency == null) {
      throw new Error(`Invalid value of currency select: ${ev.target.value}`);
    }

    setExpenseInfo({
      ...expenseInfo,
      expense: {
        ...expense,
        currency: newCurrency,
      },
    });

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          currency: newCurrency,
        });
      } catch (err) {
        console.error(`Failed to update expense currency`);
        // Reset to the previous value.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleWorkPercentageBlurOrClick(
    ev: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>,
  ) {
    const target = ev.target as HTMLInputElement;
    const value = target.value;
    const newWorkPercentage: number = Number.parseInt(value);
    if (
      !Number.isInteger(newWorkPercentage) ||
      newWorkPercentage < 0 ||
      newWorkPercentage > 100
    ) {
      console.log(`Invalid value of work percentage: ${value}`);
      target.classList.add("is-invalid");
      return;
    } else {
      target.classList.remove("is-invalid");
    }

    if (
      (workPercentage == null || workPercentage === 0) &&
      newWorkPercentage > 0
    ) {
      setShowWorkPercentagePopover(true);
      return;
    }

    if (
      workPercentage != null &&
      workPercentage > 0 &&
      newWorkPercentage === 0
    ) {
      setShowWorkPercentagePopover(true);
      return;
    }

    setExpenseInfo({
      ...expenseInfo,
      workPercentage: newWorkPercentage,
    });

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          workPercentage: newWorkPercentage,
        });
      } catch (err) {
        console.error(`Failed to update expense work percentage`);
        // Reset to the previous value.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleWorkPercentagePopoverCancel() {
    if (workPercentageControl.current == null) {
      throw new Error(
        "workPercentageControl is not rendered even though the popover is shown.",
      );
    }
    workPercentageControl.current.value =
      workPercentage == null ? "" : workPercentage.toString();
    setShowWorkPercentagePopover(false);
  }

  function handleDatePopoverCancel() {
    if (dateControl.current == null) {
      throw new Error(
        "dateControl is not rendered even though the popover is shown.",
      );
    }
    dateControl.current.value = date != null ? date : "";
    setShowDatePopover(false);
  }

  function handleWorkPercentagePopoverOkClick(
    ev: React.MouseEvent<HTMLButtonElement>,
  ) {
    if (workPercentageControl.current == null) {
      throw new Error(
        "workPercentageControl is not rendered even though the popover is shown.",
      );
    }
    const newWorkPercentage: number = Number.parseInt(
      workPercentageControl.current.value,
    );

    setShowWorkPercentagePopover(false);

    // TODO: This is duplciated in handleWorkPercentageBlur. Refactor to a common function.
    setExpenseInfo({
      ...expenseInfo,
      workPercentage: newWorkPercentage,
    });

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          workPercentage: newWorkPercentage,
        });
      } catch (err) {
        console.error(`Failed to update expense work percentage`);
        // Reset to the previous value.
        setExpenseInfo(expenseInfo);
      }
    })();
  }

  function handleDatePopoverOkClick(ev: React.MouseEvent<HTMLButtonElement>) {
    if (dateControl.current == null) {
      throw new Error(
        "dateControl is not rendered even though the popover is shown.",
      );
    }
    const newDate: string = dateControl.current.value;

    setShowDatePopover(false);

    // TODO: This is to some extent duplciated in handleDateBlur. Refactor to a common function.

    // Since the date popover is shown only when the year changes, we can assume that this expense
    // doesn't belong to the year we're displaying.
    setExpenseInfo(undefined);

    (async () => {
      try {
        await updateExpense(expenseInfo.expenseId, {
          date: newDate,
        });
      } catch (err) {
        // TODO: We can't reset to the previous value, since setExpenseInfo doesn't support setting
        // the expense to null and then setting it to a new value again. We should refactor this.
        console.error("Failed to post expense date update");
        window.location.reload();
      }
    })();
  }

  async function handleDeleteClick() {
    setExpenseInfo(undefined);

    try {
      await deleteExpense(expenseInfo.expenseId);
    } catch (err) {
      console.error(`Failed to delete expense ${expenseInfo.expenseId}`);
      window.location.reload();
      // TODO: Can't roll-back, since setExpenseInfo doesn't support calling undefined and then setting an object again.
    }
  }

  return (
    <>
      <tr style={cellBorderStyle}>
        <td
          style={{ ...EXPAND_COL_STYLE, ...cellBorderStyle }}
          onClick={toggleIsExpanded}
        >
          <button className="btn" style={{ border: "none" }}>
            {isExpanded ? "⌃" : "⌄"}
          </button>
        </td>
        <td
          style={{ ...NAME_COL_STYLE, ...cellBorderStyle }}
          onClick={isEditingName ? undefined : toggleIsExpanded}
        >
          {!isExpanded ? (
            <span>{name}</span>
          ) : !isEditingName ? (
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
              }}
            >
              {name}{" "}
              <button
                className="btn"
                onClick={(ev) => {
                  // If we wouldn't stop propagation here, it would trigger the onClick listener of
                  // the parent <tr> element, which toggles the expanded view.
                  ev.stopPropagation();
                  setIsEditingName(true);
                }}
              >
                📝
              </button>
            </div>
          ) : (
            <input
              type="text"
              autoFocus
              className="form-control"
              defaultValue={name}
              onBlur={handleNameBlur}
              onKeyPress={blurOnEnter}
              style={{ width: "100%" }}
            />
          )}
        </td>
        <td
          style={{ ...DEDUCT_COL_STYLE, ...cellBorderStyle }}
          onClick={toggleIsExpanded}
        >
          <div>
            {formatPrice(
              workPercentage === 0 || workPercentage == null
                ? amount
                : deductAmount(amount, workPercentage),
              currency,
            )}
          </div>

          {workPercentage != null &&
            workPercentage > 0 &&
            workPercentage < 100 && (
              <div style={{ color: "grey" }}>
                {workPercentage} % von {formatPrice(amount, undefined)}
              </div>
            )}
        </td>
      </tr>
      {isExpanded && (
        // Light-grey background:
        <tr style={expandedRowStyle}>
          <td style={{ ...cellBorderStyle, ...detailsCellStyle }}></td>
          <td style={{ ...cellBorderStyle, ...detailsCellStyle }} colSpan={3}>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Preis:</label>
              <div
                className="col-sm-10"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <MoneyAmountInput
                  defaultValue={formatPrice(amount)}
                  onKeyPress={blurOnEnter}
                  onBlur={handleAmountBlur}
                  style={{ width: "200px" }}
                />
                <select
                  defaultValue={currency}
                  className="form-select"
                  onBlur={handleCurrencyBlur}
                  style={{ maxWidth: "max-content" }}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <option key={currency} value={currency}>
                        {shortCurrencyString(currency)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Arbeitsanteil:</label>
              <div className="col-sm-10">
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    maxWidth: "90px",
                  }}
                >
                  {/* There's no blur event when clicking the up/down buttons on the input type=number.
                      As an easy hacky solution, we also listen for click events.
                    */}
                  <input
                    type="number"
                    ref={workPercentageControl}
                    className="form-control"
                    defaultValue={
                      workPercentage == null ? undefined : workPercentage
                    }
                    onBlur={handleWorkPercentageBlurOrClick}
                    onClick={handleWorkPercentageBlurOrClick}
                    min="0"
                    max="100"
                    step="10"
                    required
                    style={{ width: "100%", paddingRight: "20px" }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: 0,
                      bottom: 0,
                      lineHeight: "38px",
                      pointerEvents: "none",
                    }}
                  >
                    %
                  </span>
                </div>
                <Overlay
                  target={workPercentageControl}
                  show={showWorkPercentagePopover}
                  placement="bottom"
                >
                  <Popover
                    id="popover-contained"
                    onBlur={handleWorkPercentagePopoverCancel}
                  >
                    <Popover.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      {(workPercentage == null || workPercentage === 0) && (
                        <div>Ausgabe zu beruflichen Ausgaben verschieben?</div>
                      )}
                      {workPercentage === 100 && (
                        <div>Ausgabe zu privaten Ausgaben verschieben?</div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          justifyContent: "end",
                        }}
                      >
                        <button
                          className="btn btn-secondary"
                          onClick={handleWorkPercentagePopoverCancel}
                        >
                          Abbrechen
                        </button>
                        <button
                          className="btn btn-primary"
                          autoFocus
                          onClick={handleWorkPercentagePopoverOkClick}
                        >
                          OK
                        </button>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Datum:</label>
              <div className="col-sm-10">
                <input
                  type="date"
                  className="form-control"
                  defaultValue={date}
                  onKeyPress={blurOnEnter}
                  onBlur={handleDateBlur}
                  ref={dateControl}
                  style={{ maxWidth: "max-content" }}
                />
                <Overlay
                  target={dateControl}
                  show={showDatePopover}
                  placement="bottom"
                >
                  <Popover
                    id="popover-contained"
                    onBlur={handleDatePopoverCancel}
                  >
                    <Popover.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div>Ausgabe in anderes Jahr verschieben?</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          justifyContent: "end",
                        }}
                      >
                        <button
                          className="btn btn-secondary"
                          onClick={handleDatePopoverCancel}
                        >
                          Abbrechen
                        </button>
                        <button
                          className="btn btn-primary"
                          autoFocus
                          onClick={handleDatePopoverOkClick}
                        >
                          OK
                        </button>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Händler:</label>
              <div className="col-sm-10">
                <input
                  className="form-control col-sm-10"
                  onKeyPress={blurOnEnter}
                  onBlur={handleVendorBlur}
                  type="text"
                  defaultValue={vendor}
                  style={{ maxWidth: "300px" }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Belege:</label>
              <div className="col-sm-10">
                <ul style={{ paddingLeft: "20px" }}>
                  {receipts.map((receipt) => (
                    <li key={receipt.documentHash}>
                      <DocumentLink
                        documentInfo={receipt}
                        useContentKindLabel={true}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <button className="btn btn-danger" onClick={handleDeleteClick}>
                Löschen
              </button>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
